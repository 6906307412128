import { configureStore } from "@reduxjs/toolkit";
import { defaultReducer } from "./reducers";
import { TAG_ACTIVE_STATUS } from "../Global.constants";

export const initialState = {
  tagActiveStatus: TAG_ACTIVE_STATUS.NONE,
  pathBeforeThankYouPage: "/",
};

export const store = configureStore({
  reducer: defaultReducer,
});
