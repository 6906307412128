import React, { useState, useEffect } from "react";
import "./BrandsNumCard.css";
import Counter from "../Utils/Counter";

export default function BrandsNumCard({ brandsNumItem, screenWidth }) {
  const [extraStyle, setExtraStyle] = useState({});

  useEffect(() => {
    if (brandsNumItem.hasRightBorder) {
      setExtraStyle({
        borderRight: "1px solid #d3d3d3",
      });
    }
    if (screenWidth <= 960) {
      setExtraStyle({});
    }
  }, [brandsNumItem, screenWidth]);

  return (
    <li className="cards-item brands-num-card" style={extraStyle}>
      <h3 className="large-title brands-num-title">
        <Counter to={brandsNumItem.title} />
      </h3>
      <p className={brandsNumItem.textClass || "brands-num-info"}>
        {brandsNumItem.info}
      </p>
    </li>
  );
}
