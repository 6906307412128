import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ThankYou.css";
import CaseStudyCard from "../OurWork/CaseStudyCard";
import BlogCard from "../Blogs/BlogCard";
import { ourWorkItems } from "../OurWork/OurWork.constants";
import { useSelector } from "react-redux";

export default function ThankYou({ screenWidth }) {
  const [count, setCount] = useState(10);
  const [blogItems, setBlogItems] = useState([]);
  const [filteredBlogItems, setFilteredBlogItems] = useState([]);
  const navigate = useNavigate();
  const pathBeforeThankYouPage = useSelector(
    (state) => state.pathBeforeThankYouPage
  );

  const goPrevPage = () => {
    navigate(pathBeforeThankYouPage ? pathBeforeThankYouPage : "/");
  };

  const goContactUsPage = () => {
    navigate("/contact-us");
  };

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const blogRes = await fetch(
          `${process.env.REACT_APP_BLOGS_PRODUCTION}/api/blogs?populate=*&sort[0]=publishedDate:desc&sort[1]=id:desc`
        );
        const blogJson = await blogRes.json();
        if (blogJson?.data) {
          setBlogItems(blogJson?.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlogPosts();
  }, []);

  useEffect(() => {
    // Only set up the interval if count is greater than 0
    if (count > 0) {
      const timerId = setInterval(() => {
        setCount(count - 1);
      }, 1000);

      // Clear the interval on component unmount
      return () => clearInterval(timerId);
    } else {
      goPrevPage();
    }
  }, [count]);

  useEffect(() => {
    let filteredRes = [];
    if (blogItems.length > 0) {
      filteredRes = [];
      filteredRes.push(
        blogItems.find(
          (blogItem) => blogItem?.attributes?.tag.trim() === "SEO"
        ),
        blogItems.find(
          (blogItem) => blogItem?.attributes?.tag.trim() === "Industry Trends"
        ),
        blogItems.find(
          (blogItem) => blogItem?.attributes?.tag.trim() === "Social Media"
        )
      );
    }
    setFilteredBlogItems(filteredRes);
  }, [blogItems]);

  return (
    <div className="page">
      <div className="main-content">
        <section className="section thank-you-section">
          <div className="thank-you-title-container">
            <h2 className="thank-you-title-small">
              We've got your message!
              <img
                className="thank-you-title-img"
                src="/images/Sketch-Img.png"
                alt="Sketch-Img.png"
              ></img>
            </h2>
            <h2 className="thank-you-title-gradient">
              Thank you for contacting us.
            </h2>
          </div>
          <p className="thank-you-text">
            Our team will get back to you as soon as possible.
          </p>
          <div className="thank-you-button-container">
            <div
              className="thank-you-button button-transparent"
              onClick={goContactUsPage}
            >
              Send Us Another Message
            </div>
            <div
              className="thank-you-button button-primary"
              onClick={goPrevPage}
            >
              Continue Browsing
            </div>
          </div>
          <p className="count-down-text">
            This page will close automatically in {count} seconds.
          </p>
        </section>

        <section className="section">
          <div className="case-study">
            <div
              className="title-container text-to-text-margin"
              style={{ justifyContent: "flex-start" }}
            >
              Check out our featured case studies:
            </div>
            {ourWorkItems.map((ourWorkItem, index) => (
              <CaseStudyCard
                index={index}
                screenWidth={screenWidth}
                ourWorkItem={ourWorkItem}
              />
            ))}
          </div>
        </section>

        <section className="section">
          <div className="blog-section">
            <div
              className="title-container text-to-text-margin"
              style={{ justifyContent: "flex-start" }}
            >
              Check out our featured blogs
            </div>
            {filteredBlogItems.map((blogItem) => (
              <BlogCard
                key={blogItem?.attributes?.blogTitle}
                blogItem={blogItem}
              />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
