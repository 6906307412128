import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import SEO from "../../Utils/SEO.js";
import "./BlogPage.css";
import ArticleGenerator from "../../ArticleGenerator/ArticleGenerator.js";
import { formatDate } from "../../Utils/Methods.js";
import {
  FacebookShare,
  WhatsappShare,
  LinkedinShare,
  TwitterShare,
  RedditShare,
  WeiboShare,
  EmailShare,
} from "react-share-kit";
import { Link } from "react-router-dom";
import { DiscussionEmbed } from "disqus-react";

const BlogPage = ({ screenWidth }) => {
  const location = useLocation();
  const blogSlug = location.pathname.split("/").pop();
  const [blogId, setBlogId] = useState(0);
  const [blogItem, setBlogItem] = useState({});
  const [blogTitle, setBlogTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("Loading...");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");

  const shareButtonProps = {
    url: window.location.href,
    size: screenWidth > 960 ? "48px" : "32px",
    borderRadius: "8px",
    title: `Check out this website: ${blogTitle}`,
  };

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const blogRes = await fetch(
          `${process.env.REACT_APP_BLOGS_PRODUCTION}/api/blogs?filters[blogSlug][$eq]=${blogSlug}&populate=*`
        );
        const blogJson = await blogRes.json();
        if (blogJson?.data) {
          const attributes = blogJson?.data[0]?.attributes;
          setBlogId(blogJson?.data[0].id);
          setBlogItem(attributes);
          setBlogTitle(attributes?.blogTitle);
          setMetaTitle(attributes?.metaTitle);
          setMetaDescription(attributes?.metaDescription);
          setMetaKeywords(attributes?.metaKeywords);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlogPosts();
  }, [location.pathname]);

  return (
    <div className="page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
      />
      <div className="main-content">
        <section className="section" style={{ paddingTop: 0 }}>
          <Link to="/blogs" style={{ textDecoration: "none" }}>
            <button className="blog-button">
              {screenWidth > 960 ? "All Blogs" : ""}
              <ArrowLeftOutlined
                style={{ color: "var(--theme-color-primary)" }}
              />
            </button>
          </Link>

          {/* <div className="detail-blog-tag">{blogItem?.tag}</div> */}
          <div
            className="share-button-container"
            style={{ height: shareButtonProps.size }}
          >
            <FacebookShare
              url={shareButtonProps.url}
              size={shareButtonProps.size}
              borderRadius={shareButtonProps.borderRadius}
              title={shareButtonProps.title}
            />
            <TwitterShare
              url={shareButtonProps.url}
              size={shareButtonProps.size}
              borderRadius={shareButtonProps.borderRadius}
              title={shareButtonProps.title}
            />
            <WhatsappShare
              url={shareButtonProps.url}
              size={shareButtonProps.size}
              borderRadius={shareButtonProps.borderRadius}
              title={shareButtonProps.title}
            />
            <LinkedinShare
              url={shareButtonProps.url}
              size={shareButtonProps.size}
              borderRadius={shareButtonProps.borderRadius}
              title={shareButtonProps.title}
            />
            <RedditShare
              url={shareButtonProps.url}
              size={shareButtonProps.size}
              borderRadius={shareButtonProps.borderRadius}
              title={shareButtonProps.title}
            />
            <WeiboShare
              url={shareButtonProps.url}
              size={shareButtonProps.size}
              borderRadius={shareButtonProps.borderRadius}
              title={shareButtonProps.title}
            />
            <EmailShare
              url={shareButtonProps.url}
              size={shareButtonProps.size}
              borderRadius={shareButtonProps.borderRadius}
              title={shareButtonProps.title}
            />
          </div>
          <div className="website-title-blog">{blogTitle}</div>
          <div className="blog-page-date-container">
            <span>{formatDate(blogItem?.publishedDate)}</span>
            <span>{blogItem?.authorName}</span>
          </div>

          <div
            className="blog-page-img"
            style={{
              backgroundImage: `url(${blogItem?.coverImage?.data.attributes.url})`,
            }}
          />

          <ArticleGenerator textItems={blogItem?.blogContent} />

          <div style={{ height: "48px" }}></div>

          <DiscussionEmbed
            shortname="v-max"
            config={{
              url: window.location.href,
              identifier: blogId,
              title: blogTitle,
            }}
          />
        </section>
      </div>
    </div>
  );
};

export default BlogPage;
