import React, { useState, useEffect, useRef } from "react";
import "./AboutUs.css";
import CompanyIcons from "../../CompanyIcons/CompanyIcons";
import MemberCard from "./MemberCard";
import InfiniteItemsLoop from "../../InfiniteItemsLoop/InfiniteItemsLoop";
import ContactForm from "../ContactUs/ContactForm";
import ExperienceCard from "./ExperienceCard";
import VideoComponent from "../../Video/VideoComponent";
import { members, recoVariants, experienceData } from "./AboutUs.constants";

export default function AboutUs({ screenWidth }) {
  return (
    <div className="page">
      <div className="main-content">
        <section className="section header-section">
          <h4>About Us</h4>
          <div className="header-title-container">
            <h1>We Bridge the Gap</h1>
            <h2>
              From the Idea to the Screen
              {screenWidth > 960 && (
                <>
                  <img
                    className="header-round-img"
                    src="/images/Headr_Round_Img.webp"
                    alt="Headr_Round_Img.webp"
                    style={{ top: "-45px", left: "-55px" }}
                  />
                  <img
                    className="header-sparkle-img"
                    src="/images/Header_Sparkle.webp"
                    alt="Header_Sparkle.webp"
                    style={{ top: "-30px", right: "-70px" }}
                  />
                </>
              )}
            </h2>
          </div>
          <p>
            We are a team of creatives, digital marketers, and developers
            focused on unlocking growth for brands and businesses. We are based
            in Vancouver but are from all over the world, bringing a
            multicultural viewpoint that fuels data-driven creative solutions to
            our clients.
          </p>
          <div className="header-tag-container">
            {screenWidth <= 960 && (
              <>
                <img
                  className="header-round-img"
                  src="/images/Headr_Round_Img.webp"
                  alt="Headr_Round_Img.webp"
                  style={{ top: "-30px", left: "5px" }}
                />
                <img
                  className="header-sparkle-img"
                  src="/images/Header_Sparkle.webp"
                  alt="Header_Sparkle.webp"
                  style={{ top: "30px", right: "20px" }}
                />
              </>
            )}
            {["Digital Marketing", "Social Media", "Idea Creation"].map(
              (tagName) => (
                <div className="header-tag">
                  <div className="header-symbol-container">#</div>
                  <div>{tagName}</div>
                </div>
              )
            )}
          </div>
          {screenWidth <= 960 && (
            <div className="header-video-container">
              <div className="video-wrapper">
                <VideoComponent
                  borderRadius={`${screenWidth > 960 ? "20px" : "0"}`}
                  videoId={867252803}
                />
              </div>
            </div>
          )}
        </section>

        <section className="full-section">
          <div className="row-direction-container about-us-years-container">
            {screenWidth > 960 && (
              <div className="about-us-video-container">
                <div className="video-wrapper">
                  <VideoComponent borderRadius="20px" videoId={867252803} />
                </div>
              </div>
            )}
            <div className="column-direction-container about-us-video-text-container">
              {experienceData.map((experienceItem) => (
                <ExperienceCard experienceItem={experienceItem} />
              ))}
            </div>
          </div>
        </section>

        <section className="full-section exe-team-section">
          <div className="full-section-padding-wrapper">
            <img
              className="exe-team-section-bg-left"
              src={`/images/BG_Color_Gradient_${
                screenWidth > 960 ? "1" : "4"
              }.png`}
              alt="BG_Color_Gradient_1"
            />
            <img
              className="exe-team-section-bg-right"
              src={`/images/BG_Color_Gradient_${
                screenWidth > 960 ? "5" : "3"
              }.png`}
              alt="BG_Color_Gradient_2"
            />
            <div className="title-container">Our Executive Team</div>
            <div className="cards-container">
              <ul className="cards-items member-cards-items">
                {members.map((member) => (
                  <div key={member.id}>
                    <MemberCard key={member.id} member={member} />
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className="full-section ">
          <div className="title-container">Our Partners</div>
          <InfiniteItemsLoop screenWidth={screenWidth} />
        </section>
        <section className="section">
          <div className="title-container">Platforms We Are Skilled In</div>

          <CompanyIcons screenWidth={screenWidth} />
        </section>

        <section className="section">
          <ContactForm
            text={`Let’s Make Your Next Project a Success`}
            screenWidth={screenWidth}
          />
        </section>
      </div>
    </div>
  );
}
