import { useState, useEffect } from "react";
import "./ArticleGenerator.css";
import CodeGenerator from "./CodeGenerator";

const generateNodes = (textItems) => {
  return textItems.map((textItem) => {
    switch (textItem.type) {
      case "heading":
        switch (textItem.level) {
          case 1:
            return (
              <div className="website-title-blog">
                {textItem.children.map((hItem) => (
                  <span>{hItem.text ? hItem.text : "\n"}</span>
                ))}
              </div>
            );
          case 2:
            return (
              <h2>
                {textItem.children.map((hItem) => (
                  <span
                    className={`${
                      hItem.bold ? "heading-bold" : "heading-regular-bold"
                    }`}
                  >
                    {hItem.text ? hItem.text : "\n"}
                  </span>
                ))}
              </h2>
            );
          case 3:
            return (
              <h3>
                {textItem.children.map((hItem) => (
                  <span
                    className={`${
                      hItem.bold ? "text-bold" : "heading-regular-bold"
                    }`}
                  >
                    {hItem.text ? hItem.text : "\n"}
                  </span>
                ))}
              </h3>
            );
        }
        break;
      case "list":
        switch (textItem.format) {
          case "unordered":
            return (
              <ul>
                {textItem.children.map((listItem) => (
                  <li>
                    <p>
                      {listItem.children.map((pItem) => (
                        <span
                          className={`${
                            pItem.bold ? "text-bold" : "text-regular-bold"
                          }`}
                        >
                          {pItem.text ? pItem.text : "\n"}
                        </span>
                      ))}
                    </p>
                  </li>
                ))}
              </ul>
            );
        }
        break;
      case "code":
        const codeText = textItem?.children[0]?.text;
        return <CodeGenerator codeText={codeText} />;
      default:
        return (
          <p>
            {textItem.children.map((pItem) => (
              <span
                className={`${pItem.bold ? "text-bold" : "text-regular-bold"}`}
              >
                {pItem.text ? pItem.text : "\n"}
              </span>
            ))}
          </p>
        );
    }
  });
};

export default function ArticleGenerator({ textItems }) {
  // useEffect(() => {
  //   console.log(textItems);
  // }, [textItems]);

  return (
    <div className="blog-text">{textItems && generateNodes(textItems)}</div>
  );
}
