import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import "./ContactUs.css";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 49.189417,
  lng: -123.141594,
};

function MyMapComponent() {
  const [map, setMap] = useState(null);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  useEffect(() => {
    if (map) {
      map.panTo(center);
    }
  }, [map]);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}>
      <div className="google-map-container">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={13}
          onLoad={onLoad}
          disableDefaultUI={true}
        >
          <MarkerF position={{ lat: 49.189417, lng: -123.141594 }} />
        </GoogleMap>
      </div>
    </LoadScript>
  );
}

export default MyMapComponent;
