export const portfolioVideos = [
  {
    videoId: 916150200,
    thumbnailUrl: "/images/OurWork/our-work-thumb-1.png",
    title: "B.Box Christmas Big Sales",
  },
  {
    videoId: 916054371,
    thumbnailUrl: "/images/OurWork/our-work-thumb-2.png",
    title: "Explore the Beauty of Tofino",
  },
  {
    videoId: 916150458,
    thumbnailUrl: "/images/OurWork/our-work-thumb-3.png",
    title: "Our Works With Influencers",
  },
];

export const ourWorkItems = [
  {
    logoSrc: "/images/OurWork/B_Box_logo.png",
    coverSrc: "/images/OurWork/B_Box.jpg",
    title: "B.Box Canada Witnesses a Remarkable 400% Surge in Online Sales",
    text: "How strategic interventions lifted B.Box Canada to new heights in online sales and social media engagement.",
    buttonLink:
      "https://case-study-baby.s3.us-east-2.amazonaws.com/V_Max_Case_Study_B_Box.pdf",
  },
  {
    logoSrc: "/images/OurWork/APK_logo.png",
    coverSrc: "/images/OurWork/APK.png",
    title: "APK Elevates Social Presence Using Dynamic Content Production",
    text: "Discover how Academics PreKindergarten (APK) soared to new heights in social media presence with the help of V-Max.",
    buttonLink: "https://case-study-baby.s3.us-east-2.amazonaws.com/APK.pdf",
  },
];
