import React from "react";
import "./CollapseCard.css";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

const iconStyle = {
  fontSize: "32px",
};

export default function CollapseCard({ collapseItems }) {
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({ isActive }) =>
        isActive ? (
          <MinusOutlined style={iconStyle} />
        ) : (
          <PlusOutlined style={iconStyle} />
        )
      }
      items={collapseItems}
    />
  );
}
