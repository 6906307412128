import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./NavText.css";
import { useNavigate } from "react-router-dom";
import Icon from "../Icon/Icon";

export default function NavText({ navTo, text, type = "default" }) {
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      className={`nav-text-container ${
        type === "blogAd" && "blog-ad-nav-container"
      }`}
    >
      <motion.div
        className="nav-text-button"
        whileHover={{ scale: 1.2 }}
        transition={{ duration: 0.5 }}
        onClick={() => navigate(navTo)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <span className={`${isHover ? "gradient-color-text" : "default-text"}`}>
          {text}
        </span>
        <Icon
          className={`right-arrow-icon ${
            type === "blogAd" && "blog-right-arrow-icon"
          }`}
          status={isHover ? "hover" : "default"}
          srcList={[
            { status: "default", src: "/images/Right_Arrow.svg" },
            { status: "hover", src: "/images/Gradient_Right_Arrow.svg" },
          ]}
        />
      </motion.div>
    </div>
  );
}
