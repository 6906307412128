export const serviceItems = [
  {
    url: "/images/Icons/Social_media_management.svg",
    id: 1,
    title: "Social Media Management",
    description:
      "Experience business growth as we elevate your brand through expert Social Media Management. We create a thriving online community, fostering engagement and ensuring your brand stands out.",
  },
  {
    url: "/images/Icons/SEO1.svg",
    id: 2,
    title: "SEO",
    description: `We optimize your online visibility and boost business growth with our expert SEO services, tailored to elevate your website's ranking and drive sustainable success.`,
  },
  {
    url: "/images/Icons/Paid_Media.svg",
    id: 3,
    title: "Paid Media Ads",
    description:
      "Accelerate your online presence with our strategic Paid Media Ads services. Crafted by experts, these ads maximize visibility, engagement, and deliver tangible results for your business.",
  },
  {
    url: "/images/Icons/E-Commerce.svg",
    id: 4,
    title: "E-Commerce",
    description:
      "We transform your online store into a thriving marketplace with our specialized E-commerce solutions, designed to enhance user experience, drive sales, and ensure sustainable digital success.",
  },
  {
    url: "/images/Icons/Influencer.svg",
    id: 5,
    title: "Influencer Marketing",
    description: `Elevate your brand's influence and reach new heights through impactful collaborations with authentic influencers. Our influencer marketing solutions increase brand awareness and drive sales.`,
  },
  {
    url: "/images/Icons/Brand.svg",
    id: 6,
    title: "Branding",
    description:
      "We define and amplify your brand identity with our comprehensive Branding services.We craft brand logo, color scheme, typography, and brand story.",
  },
];

export const gradientItems = [
  {
    key: 1,
    src: "/images/Icons/Brand.svg",
    title: "40+",
    info: "local brands",
  },
  {
    key: 2,
    src: "/images/Icons/Content.svg",
    title: "5000+",
    info: "video content generated per year",
  },
  {
    key: 3,
    src: "/images/Icons/Revenue.svg",
    title: "$2M+",
    info: "Revenue for our clients",
  },
];

export const makerItems = [
  {
    key: 1,
    src: "/images/Icons/Creative.svg",
    title: "Creative",
    info: `Content creation \n
    Copywriting \n
    Web development \n
    Influencer collaboration`,
  },
  {
    key: 2,
    src: "/images/Icons/Production.svg",
    title: "Production",
    info: `Video Production \n
    Graphic Design \n
    Photography \n
    Live Stream`,
  },
  {
    key: 3,
    src: "/images/Icons/Marketing.svg",
    title: "Marketing",
    info: `E-Commerce \n
    Digital content marketing \n
    Paid media Ads \n
    Brand event`,
  },
];

export const builtOnItems = [
  {
    title: "Brand Relationship Development",
    url: "/images/DigitalMarketing/Relationship.svg",
    iconWidth: "70%",
  },
  {
    title: "Tailor Made Solutions",
    url: "/images/DigitalMarketing/Solution.svg",
    iconWidth: "65%",
  },
  {
    title: "Target Audience Enhancement",
    url: "/images/DigitalMarketing/Target_01.svg",
    iconWidth: "75%",
  },
  {
    title: "Creative Content Development",
    url: "/images/DigitalMarketing/Contents_development.svg",
    iconWidth: "80%",
  },
  {
    title: "Influencer Collaboration",
    url: "/images/DigitalMarketing/Cooperation.svg",
    iconWidth: "85%",
  },
  {
    title: "Seamless Shopping Experience",
    url: "/images/DigitalMarketing/Shooping_experience.svg",
    iconWidth: "50%",
  },
  {
    title: "Performance / Data Driven Campaigns",
    url: "/images/DigitalMarketing/target_W.webp",
  },
  {
    title: "Adapt to Emerging Trends",
    url: "/images/DigitalMarketing/Trend_02.svg",
  },
];

export const weDoItems = [
  {
    title: "CREATIVE",
    url: "/images/DigitalMarketing/Brand_Creative.svg",
    info: (
      <>
        <li>Content Creation</li>
        <li>Copywriting</li>
        <li>Web Development</li>
        <li>Influencer collaboration</li>
      </>
    ),
  },
  {
    title: "PRODUCTION",
    url: "/images/DigitalMarketing/Production.svg",
    info: (
      <>
        <li>Live Stream</li>
        <li>Graphic Design</li>
        <li>Photography</li>
        <li>Video Production</li>
      </>
    ),
  },
  {
    title: "MARKETING",
    url: "/images/DigitalMarketing/Brand_marketing.svg",
    info: (
      <>
        <li>E-Commerce</li>
        <li>Digital Content Marketing</li>
        <li>Paid Media Ads</li>
        <li>Brand Event</li>
      </>
    ),
  },
];

export const socialComItems = [
  {
    url: "/images/DigitalMarketing/Brand_online_shop_W.svg",
    text: "Social Media Store set-up",
    iconWidth: "70%",
  },
  {
    url: "/images/DigitalMarketing/Brand_TikTok_Shop_W.svg",
    text: "Live Shopping",
    iconWidth: "90%",
  },
  {
    url: "/images/DigitalMarketing/Brand_Ads_W.svg",
    text: "Shoppable ads",
  },
  {
    url: "/images/DigitalMarketing/Brand_Showcase.svg",
    text: "Product Showcase",
  },
  {
    url: "/images/DigitalMarketing/Brand_target_W.svg",
    text: "Tagged Content",
    iconWidth: "85%",
  },
];

export const brandBoardItems = [
  {
    text: "One-stop Full Funnel Solution",
  },
  {
    text: "Brand Strategy Consulting",
  },
  {
    text: "Real-time Data Visualization",
  },
  {
    text: "High Scalability Customization",
  },
];

export const brandImg1Variants = {
  offscreen: {
    y: 20,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.3,
      ease: "easeInOut",
    },
  },
};

export const brandImg2Variants = {
  offscreen: {
    y: 20,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

export const trueExportsVideos = [
  {
    videoId: 874208590,
    thumbnailUrl: "/images/DigitalMarketing/Tiktok_Cosmetic_Scene.jpg",
  },
  {
    videoId: 874208632,
    thumbnailUrl: "/images/DigitalMarketing/Tiktok_Fashion_Scene.jpg",
  },
  {
    videoId: 874208660,
    thumbnailUrl: "/images/DigitalMarketing/Tiktok_Outdoor_Scene.png",
  },
];

export const verticalItems = [
  "Art",
  "Food",
  "Music",
  "Tech",
  "Auto",
  "Fashion",
  "Beauty",
  "Travel",
  "Dancing",
  "Gaming",
  "Lifestyle",
  "Animals & Pet",
  "Outdoor",
  "Business",
  "Fitness & Sports",
];
