export const intro = `We are a one-stop shop for all your digital, creative, and production needs.`;
export const whyWork = `Our team boasts years of marketing expertise, ensuring that your digital strategies are not only cutting-edge but also rooted in a deep understanding of market dynamics.`;

export const digitalHeader = `Our 
                                \n Digital Marketing 
                                \n Services`;

export const mainNumItems = [
  {
    title: "25+",
    info: "years of combined experience in Marketing",
    hasRightBorder: true,
  },
  {
    title: "10+",
    info: "years of combined experience in Film Production",
    hasRightBorder: true,
  },
  {
    title: "20+",
    info: "countries’ team members with diverse cultural backgrounds",
    hasRightBorder: true,
  },
  {
    title: "500+",
    info: "brand video creation",
  },
];

export const offerItems = [
  {
    title: "Ideas Creation",
    photoSrc: "/images/Creators/Idea_Creation.webp",
  },
  {
    title: "Script Writing",
    photoSrc: "/images/Creators/Script_writing.webp",
  },
  {
    title: "Professional Video Shooting & Editing",
    photoSrc: "/images/Creators/Professional_shooting.webp",
  },
  {
    title: "Content Distribution",
    photoSrc: "/images/Creators/Contents_Distribution.webp",
  },
  {
    title: "Social Accounts Management",
    photoSrc: "/images/Creators/Social_Accounts.webp",
  },
  {
    title: "Audience Growth",
    photoSrc: "/images/Creators/Followers_Growth.webp",
  },
  {
    title: "Marketing Promotion",
    photoSrc: "/images/Creators/Marketing_Promotion.webp",
  },
  {
    title: "Personal Training",
    title2: "(Skills & Career Guidance)",
    photoSrc: "/images/Creators/Personal_Training.webp",
  },
  {
    title: "Brand & Commercial Opportunity",
    photoSrc: "/images/Creators/Brand_opportunity.webp",
  },
];

export const homeVideos = [
  {
    videoId: 916051616,
    thumbnailUrl: "/images/Home/home-thumb-1.png",
    title: "Our Work with B.Box",
  },
  {
    videoId: 916054371,
    thumbnailUrl: "/images/Home/home-thumb-2.png",
    title: "Our production team on set",
  },
  {
    videoId: 916053964,
    thumbnailUrl: "/images/Home/home-thumb-3.png",
    title: "Our work with Tofino",
  },
];

export const serviceCardItems = [
  {
    title: "Digital Marketing Services",
    imgSrc: "/images/Icons/Brand.svg",
    text: "We don't just make it look nice, we optimize your digital marketing to drive Real Results.",
    listText: [
      "Social Media Management",
      "Paid Media Ads",
      "Brand Building",
      "Web Development",
      "Search Engine Optimization",
    ],
    bgSide: "left",
    linkTo: "/service/digital-marketing-services-in-vancouver",
  },
  {
    title: "Video Production",
    imgSrc: "/images/Icons/Production.svg",
    text: "From the idea to the screen, we find your audience and tell your story.",
    listText: [
      "Commercials",
      "Brand Stories",
      "Documentaries",
      "Behind the Scenes",
      "Music Videos",
      "Video Edit + Color",
      "Graphic Design",
    ],
    bgSide: "left",
    linkTo: "/service/video-production-services",
  },
];

export const trustedByItems = [
  {
    spriteClassName: "b-box-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "surmesur-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "ultima-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "hegan-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "tonature-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "apk-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
];

export const companyIconItems = [
  {
    spriteClassName: "ins-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "tiktok-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "youtube-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "linkedin-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "facebook-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "x-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "wordpress-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "google-ads-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "display-360-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "shopify-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
  {
    spriteClassName: "amazon-sprite",
    desktopHeight: 48,
    mobileHeight: 36,
  },
];
