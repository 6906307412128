import { useRef, useEffect, useState } from "react";
import PartnerCard from "./PartnerCard.js";
import CustomKeyFrames from "./CustomKeyFrames";
import "./InfiniteItemsLoop.css";

let data = [
  {
    name: "To Nature",
    src: "/images/icon-1.webp",
    width: "190px",
    mobileWidth: "150px",
  },
  {
    name: "Yixin Media",
    src: "/images/icon-2.webp",
    width: "220px",
    mobileWidth: "160px",
  },
  {
    name: "YT Music Studio",
    src: "/images/icon-6.webp",
    width: "260px",
    mobileWidth: "200px",
  },
  {
    name: "Baby enRoute",
    src: "/images/icon-4.webp",
    width: "240px",
    mobileWidth: "190px",
  },
  {
    name: "B.Box",
    src: "/images/icon-5.webp",
    width: "140px",
    mobileWidth: "110px",
  },
  {
    name: "Hegen",
    src: "/images/icon-1.webp",
    width: "150px",
    mobileWidth: "115px",
  },
  {
    name: "Academics PreKindergarten",
    src: "/images/icon-2.webp",
    width: "430px",
    mobileWidth: "300px",
  },
  {
    name: "Tofino Tourism",
    src: "/images/icon-3.webp",
    width: "260px",
    mobileWidth: "200px",
  },
  {
    name: "To Nature",
    src: "/images/icon-1.webp",
    width: "190px",
    mobileWidth: "150px",
  },
  {
    name: "Yixin Media",
    src: "/images/icon-2.webp",
    width: "220px",
    mobileWidth: "160px",
  },
  {
    name: "YT Music Studio",
    src: "/images/icon-6.webp",
    width: "260px",
    mobileWidth: "200px",
  },
  {
    name: "Baby enRoute",
    src: "/images/icon-4.webp",
    width: "240px",
    mobileWidth: "190px",
  },
  {
    name: "B.Box",
    src: "/images/icon-5.webp",
    width: "140px",
    mobileWidth: "110px",
  },
  {
    name: "Hegen",
    src: "/images/icon-1.webp",
    width: "150px",
    mobileWidth: "115px",
  },
  {
    name: "Academics PreKindergarten",
    src: "/images/icon-2.webp",
    width: "430px",
    mobileWidth: "300px",
  },
  {
    name: "Tofino Tourism",
    src: "/images/icon-3.webp",
    width: "260px",
    mobileWidth: "200px",
  },
];
export default function InfiniteItemsLoop({ screenWidth }) {
  const ref = useRef(null);
  const refCard = useRef(null);
  const [containerWidth, setWidth] = useState(false);
  const [cardCount, setCardCount] = useState(0);
  // const [animationState, setPlay] = useState("paused");
  const [animationSpeedState, setAnimationSpeedState] = useState(1);
  const animationSpeedRatio = 0.2;

  useEffect(() => {
    if (refCard.current) {
      let totalWidth = 0;
      for (let item of data) {
        let widthNum = Number(item.width.split("px")[0]);
        totalWidth = totalWidth + widthNum + 20;
      }
      setWidth(totalWidth * 2);

      setCardCount(Math.ceil(screenWidth / refCard.current.scrollWidth));
      setAnimationSpeedState(data.length / animationSpeedRatio);
    }
  }, [screenWidth]);

  const renderCards = data.map((el, index) => {
    return (
      <PartnerCard
        key={index}
        cardName={el.name}
        logoSrc={el.src}
        cardWidth={screenWidth > 960 ? el.width : el.mobileWidth}
        animationSpeed={animationSpeedState}
        refCard={refCard}
      />
    );
  });

  const renderSecondCards = () => {
    let td = [];
    for (let i = 0; i <= cardCount; i++) {
      td.push(
        <PartnerCard
          key={i}
          cardName={data[i]?.name}
          logoSrc={data[i]?.src}
          cardWidth={screenWidth > 960 ? data[i]?.width : data[i]?.mobileWidth}
          animationSpeed={animationSpeedState}
        />
      );
    }
    return td;
  };

  return (
    <div className="loop-items-container">
      <div
        className="d-flex"
        ref={ref}
        style={{
          width: `${containerWidth}px`,
        }}
      >
        {renderCards}
        {renderSecondCards()}
      </div>
      <CustomKeyFrames scrollPosition={containerWidth}></CustomKeyFrames>
    </div>
  );
}
