const panelStyle = {
  marginBottom: 24,
  border: "none",
  backgroundColor: "var(--theme-color)",
  border: "solid",
  borderColor: "var(--theme-color-primary)",
  borderWidth: "1px",
};

export const videoItems = [
  {
    url: "/images/Icons/Commercials.svg",
    id: 1,
    title: "Commercials",
    description:
      "Show the essence of your brand and engage your audience with expertly crafted commercials.",
  },
  {
    url: "/images/Icons/Behind_the_scene.svg",
    id: 2,
    title: "Brand Films",
    description:
      "Integrate your brand seamlessly into short films that leave a lasting impression on your audience, making sure they remember your message long after the screen fades to black.",
  },
  {
    url: "/images/Icons/Documentaries.png",
    id: 3,
    title: "Documentaries",
    description:
      "Capture your story with depth and authenticity through our documentary production, creating narratives that resonate on a personal level.",
  },
  {
    url: "/images/Icons/Photography.svg",
    id: 4,
    title: "Photography",
    description:
      "Tell your brand's story through captivating still images, whether showcasing products, portraits, or your business.",
  },
  {
    url: "/images/Icons/Graphic_Design.svg",
    id: 5,
    title: "Graphic Design",
    description:
      "Bring your visual identity to life with our graphic design services. From logos to promotional materials, we ensure your brand visuals are compelling and aligned with your brand ethos.",
  },
  {
    url: "/images/Icons/Video.svg",
    id: 6,
    title: "Post-production",
    description:
      "Elevate your content with our post-production services, and get polished and professional images that align with your brand’s aesthetics.",
  },
];

export const vpCollapseItems = [
  {
    key: "1",
    label: "Creative Collaboration",
    children: (
      <p>
        Experience the synergy of close collaboration between our creative and
        production departments. This dynamic partnership sparks innovative ideas
        and solutions, creating content that is both imaginative and
        strategically sound.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: "Cultural Perspective",
    children: (
      <p>
        Our diverse team brings a variety of perspectives to the table, crafting
        storytelling that resonates with a broad audience. By leveraging
        different cultural viewpoints, we ensure that your content is relevant
        and impactful.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: "Cost & Time Efficiency",
    children: (
      <p>
        By eliminating external dependencies, we streamline processes for
        enhanced efficiency, ultimately translating into cost and time savings
        for your projects.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "4",
    label: "Quality",
    children: (
      <p>
        With in-house oversight, we maintain uncompromising standards to
        consistently meet and exceed client expectations.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "5",
    label: "Flexibility",
    children: (
      <p>
        Our in-house setup provides the agility needed to respond to evolving
        requirements and gives us the ability to tailor our solutions precisely
        to meet your needs.
      </p>
    ),
    style: panelStyle,
  },
];

export const offerItems = [
  {
    title: "Ideas Creation",
    photoSrc: "/images/Creators/Idea_Creation.webp",
  },
  {
    title: "Script Writing",
    photoSrc: "/images/Creators/Script_writing.webp",
  },
  {
    title: "Professional Video Shooting & Editing",
    photoSrc: "/images/Creators/Professional_shooting.webp",
  },
  {
    title: "Content Distribution",
    photoSrc: "/images/Creators/Contents_Distribution.webp",
  },
  {
    title: "Social Accounts Management",
    photoSrc: "/images/Creators/Social_Accounts.webp",
  },
  {
    title: "Audience Growth",
    photoSrc: "/images/Creators/Followers_Growth.webp",
  },
  {
    title: "Marketing Promotion",
    photoSrc: "/images/Creators/Marketing_Promotion.webp",
  },
  {
    title: "Personal Training",
    title2: "(Skills & Career Guidance)",
    photoSrc: "/images/Creators/Personal_Training.webp",
  },
  {
    title: "Brand & Commercial Opportunity",
    photoSrc: "/images/Creators/Brand_opportunity.webp",
  },
];

export const productionImgs = [
  { key: 1, imgSrc: "/images/Service/Scroll_05.webp" },
  { key: 2, imgSrc: "/images/Service/Scroll_06.webp" },
  { key: 3, imgSrc: "/images/Service/BTS3.jpg" },
  { key: 4, imgSrc: "/images/Service/Scroll_02.webp" },
];

export const bestPracticeVideos = [
  {
    videoId: 900276424,
    thumbnailUrl: "/images/Service/RED_assembly.jpg",
  },
  {
    videoId: 900277581,
    thumbnailUrl: "/images/Service/BTS_Logo_Design_final.jpg",
  },
  {
    videoId: 900277955,
    thumbnailUrl: "/images/Service/Camera_assembly_Timelapse.jpg",
  },
];
