import React from "react";
import GradientIcon from "../GradientIcon/GradientIcon";
import "./GradientCards.css";
import Counter from "../Utils/Counter";

const GradientGrid = ({ item }) => (
  <div className="gradient-grid-item">
    <GradientIcon iconWidth={{ width: "10%" }} iconSrc={item.src} />
    <h1>
      <Counter to={item.title} />
    </h1>
    <div className="gradient-text">{item.info}</div>
  </div>
);

const GradientCards = ({ gradientItems }) => {
  return (
    <div className="gradient-grid">
      {gradientItems.map((gradientItem) => (
        <GradientGrid key={gradientItem.key} item={gradientItem} />
      ))}
    </div>
  );
};

export default GradientCards;
