import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/pages/Home/Home";
import Blogs from "./components/pages/Blogs/Blogs";
import BlogPage from "./components/pages/Blogs/BlogPage";
import DigitalMarketing from "./components/pages/DigitalMarketing/DigitalMarketing";
import AboutUs from "./components/pages/AboutUs/AboutUs";
import ContactUs from "./components/pages/ContactUs/ContactUs";
import ThankYou from "./components/pages/ThankYou/ThankYou";
import Footer from "./components/Footer/Footer";
import TagManager from "react-gtm-module";
import VideoProduction from "./components/pages/VideoProduction/VideoProduction";
import OurWork from "./components/pages/OurWork/OurWork";
import PolicyPage from "./components/Footer/PolicyPage";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import Error from "./components/pages/Error/Error";

// import TestPage from "./components/pages/Test/TestPage";

//google analytic
const TagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLEANALYTIC_ID,
};
TagManager.initialize(TagManagerArgs);

function App() {
  window.dataLayer.push({
    event: "pageview",
  });

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const homeVideoRef = useRef(null);
  const [isHomeVideoInView, setIsHomeVideoInView] = useState(true);

  // useEffect(() => {}, []);

  useEffect(() => {
    const changeWidthAndHeight = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", changeWidthAndHeight);
  });

  return (
    <Provider store={store}>
      <HelmetProvider>
        <Router className="App">
          <Navbar
            screenWidth={screenWidth}
            isHomeVideoInView={isHomeVideoInView}
          />
          <Routes>
            <Route
              path="/"
              exact
              element={
                <Home
                  screenWidth={screenWidth}
                  screenHeight={screenHeight}
                  homeVideoRef={homeVideoRef}
                  setIsHomeVideoInView={setIsHomeVideoInView}
                />
              }
            />
            <Route
              path="/service/digital-marketing-services-in-vancouver"
              element={<DigitalMarketing screenWidth={screenWidth} />}
            />
            <Route
              path="/our-work"
              element={<OurWork screenWidth={screenWidth} />}
            />
            <Route
              path="/portfolio"
              element={<OurWork screenWidth={screenWidth} />}
            />
            <Route
              path="/about-us"
              element={<AboutUs screenWidth={screenWidth} />}
            />
            <Route
              path="/service/video-production-services"
              element={<VideoProduction screenWidth={screenWidth} />}
            />
            <Route
              path="/blogs"
              element={<Blogs screenWidth={screenWidth} />}
            />
            <Route
              path="/blogs/:tagSlug/:blogSlug"
              element={<BlogPage screenWidth={screenWidth} />}
            />
            <Route
              path="/lets-connect"
              element={<ContactUs screenWidth={screenWidth} />}
            />
            <Route
              path="/contact-us"
              element={<ContactUs screenWidth={screenWidth} />}
            />
            <Route
              path="/thank-you"
              element={<ThankYou screenWidth={screenWidth} />}
            />
            <Route
              path="/policies/:policySlug"
              element={<PolicyPage screenWidth={screenWidth} />}
            />
            <Route path="*" element={<Error />} />
            {/* !!! Remove this when launch*/}
            {/*<Route path="/test" exact element={<TestPage />} /> */}
            <Route />
          </Routes>
          <Footer screenWidth={screenWidth} />
        </Router>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
