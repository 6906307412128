import { useNavigate } from "react-router-dom";
import "./Error.css";
import { Link } from "react-router-dom";

export default function Error() {
  const navigate = useNavigate();

  return (
    <div className="page">
      <div className="main-content">
        <div className="section">
          <div className="column-direction-container error-flex-container-column">
            <h1>Oops!</h1>
            <p>
              We can not find the page you are looking for. <br /> Let's get you
              back on track while we fix it.
            </p>
            <Link to="/">
              <div className="go-back-button">Go To Home Page</div>
            </Link>
            <img src="/images/Error_404.webp" alt="error-404-img" />
          </div>
        </div>
      </div>
    </div>
  );
}
