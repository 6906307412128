import React, { useState, useEffect, useRef } from "react";
import "./VideoComponent.css";
import { getScaleToFullyStrech } from "../Utils/Methods";

function VideoComponent({
  videoId,
  hasUi = false,
  hasSound = false,
  width = 16,
  height = 9,
  borderRadius = "0",
  thumbnailUrl = `https://vumbnail.com/${videoId}_large.jpg`,
  horizontalStrech = false,
  verticalStrech = false,
  maskType = "",
}) {
  const [playing, setPlaying] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const playingRef = useRef(playing);
  const [scale, setScale] = useState(1);
  playingRef.current = playing;

  useEffect(() => {
    const iframe = document.querySelector(`iframe[title="Video ${videoId}"]`);
    const player = new window.Vimeo.Player(iframe);

    player.on("play", () => {
      setPlaying(true);
    });

    player.on("loaded", () => {
      setLoaded(true);
    });

    player.on("pause", () => {
      setPlaying(false);
    });

    // Cleanup event listeners on component unmount
    return () => {
      player.off("play");
      player.off("loaded");
      player.off("pause");
    };
  }, [videoId]);

  const getVideoWidth = () => {
    if (verticalStrech) {
      if (width / height <= 9 / 16) {
        return width;
      } else {
        return (height * 9) / 16;
      }
    }
    if (horizontalStrech) {
      if (width / height <= 16 / 9) {
        return width;
      } else {
        return (height * 16) / 9;
      }
    }
  };

  const getVideoHeight = () => {
    if (verticalStrech) {
      if (width / height <= 9 / 16) {
        return (width * 16) / 9;
      } else {
        return height;
      }
    }
    if (horizontalStrech) {
      if (width / height <= 16 / 9) {
        return (width * 9) / 16;
      } else {
        return height;
      }
    }
  };

  useEffect(() => {
    if (horizontalStrech || verticalStrech) {
      const containerWidth = width;
      const containerHeight = height;
      const videoWidth = getVideoWidth();
      const videoHeight = getVideoHeight();
      setScale(
        getScaleToFullyStrech(
          containerWidth,
          containerHeight,
          videoWidth,
          videoHeight
        )
      );
    } else {
      setScale(1);
    }
  }, [verticalStrech, width, height]);

  const playVideo = () => {
    const iframe = document.querySelector(`iframe[title="Video ${videoId}"]`);
    const player = new window.Vimeo.Player(iframe);
    player.ready().then(function () {
      player.play();
    });
  };

  return (
    <div
      className="embed-container"
      style={{
        "--video--width": width,
        "--video--height": height,
        paddingBottom: `calc(var(--video--height) / var(--video--width) * 100%)`,
        borderRadius: borderRadius,
      }}
    >
      {!hasUi && (
        <div
          className="video-thumbnail-container"
          style={{ backgroundImage: `url(${thumbnailUrl})` }}
        ></div>
      )}
      {maskType && (
        <div className={`${maskType === "home" && "home-mask"}`}></div>
      )}
      <div style={{ zIndex: 4, transform: `scale(${scale})` }}>
        <iframe
          className="vimeo-video"
          src={`https://player.vimeo.com/video/${videoId}?autoplay=${
            hasUi ? 0 : 1
          }&muted=${!hasSound ? 1 : 0}&background=${
            !hasUi ? 1 : 0
          }&loop=1&title=0`}
          frameborder="0"
          allow="autoplay; fullscreen"
          webkitAllowFullScreen
          mozallowfullscreen
          allowfullscreen
          title={`Video ${videoId}`}
        ></iframe>
      </div>
    </div>
  );
}

export default VideoComponent;
