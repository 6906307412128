import { animate, useInView } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

function transformStringToNumberObject(str) {
  // Regular expression to match optional characters before and after the digits
  const regex = /^([^\d]*)(\d+)([^\d]*)$/;
  const match = str.match(regex);

  if (match) {
    // Extract the parts using destructuring
    const [, leftUnit, number, rightUnit] = match;
    return {
      leftUnit: leftUnit || "", // Default to empty string if undefined
      rightUnit: rightUnit || "", // Default to empty string if undefined
      number: parseInt(number, 10), // Convert the number part to an integer
    };
  } else {
    // Return a default object structure if the string does not match the expected format
    return {
      leftUnit: "",
      rightUnit: "",
      number: 0,
    };
  }
}

export default function Counter({ from = 0, to, toFixedVal = 0, once = true }) {
  const nodeRef = useRef();
  const toNum = transformStringToNumberObject(to);
  const isInView = useInView(nodeRef);
  const [hasShown, setHasShown] = useState(false);

  useEffect(() => {
    if (isInView && !hasShown) {
      setHasShown(true);
      const node = nodeRef.current;
      const controls = animate(from, toNum.number, {
        duration: 1,
        onUpdate(value) {
          node.textContent = Number(value.toFixed(toFixedVal)).toLocaleString();
        },
      });
      // return () => controls.stop();
    }
  }, [from, to, isInView]);

  return (
    <div>
      {toNum.number ? (
        <>
          <span>{toNum.leftUnit}</span>
          <span ref={nodeRef} />
          <span>{toNum.rightUnit}</span>
        </>
      ) : (
        <>{to}</>
      )}
    </div>
  );
}
