import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactForm from "../ContactUs/ContactForm";
import { tagItems } from "./Blogs.constants";
import { TAG_ACTIVE_STATUS } from "../../../Global.constants";
import "./Blogs.css";
import BlogCard from "./BlogCard";
import { useDispatch, useSelector } from "react-redux";
import { setValue } from "../../../redux/actions";

function Blogs({ screenWidth }) {
  const tagActiveStatus = useSelector((state) => state.tagActiveStatus);
  const setTagActiveStatus = (tagName) => {
    dispatch(setValue("tagActiveStatus", tagName));
  };

  const [blogItems, setBlogItems] = useState([]);
  const [filteredBlogItems, setFilteredBlogItems] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const blogRes = await fetch(
          `${process.env.REACT_APP_BLOGS_PRODUCTION}/api/blogs?populate=*&sort[0]=publishedDate:desc&sort[1]=id:desc`
        );
        const blogJson = await blogRes.json();
        if (blogJson?.data) {
          setBlogItems(blogJson?.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlogPosts();
  }, []);

  useEffect(() => {
    if (blogItems.length) {
      let filteredRes = [];
      if (tagActiveStatus === TAG_ACTIVE_STATUS.NONE) {
        filteredRes = blogItems;
      } else {
        filteredRes = blogItems.filter(
          (blogItem) =>
            tagActiveStatus.trim().toLowerCase() ===
            blogItem.attributes?.tag.trim().toLowerCase()
        );
      }
      setFilteredBlogItems(filteredRes);
    }
  }, [tagActiveStatus, blogItems]);

  const handleTagClick = (tagName) => {
    if (tagActiveStatus === tagName) {
      setTagActiveStatus(TAG_ACTIVE_STATUS.NONE);
      return;
    }
    setTagActiveStatus(tagName);
  };

  return (
    <div className="page brands-page">
      <div className="main-content">
        <section className="section header-section">
          <h4>Blogs</h4>
          <div className="header-title-container">
            <h2>
              Stay Up-to-date with V-Max
              {screenWidth > 960 && (
                <>
                  <img
                    className="header-round-img"
                    src="/images/Headr_Round_Img.webp"
                    alt="Headr_Round_Img.webp"
                    style={{ top: "-25px", left: "-75px" }}
                  />
                  <img
                    className="header-sparkle-img"
                    src="/images/Header_Sparkle.webp"
                    alt="Header_Sparkle.webp"
                    style={{ top: "5px", right: "-60px" }}
                  />
                </>
              )}
            </h2>
            <h1>
              Latest Trends & News
              {screenWidth <= 960 && (
                <img
                  className="header-sparkle-img"
                  src="/images/Header_Sparkle.webp"
                  alt="Header_Sparkle.webp"
                  style={{ top: "50px", right: "40px" }}
                />
              )}
            </h1>
          </div>
          <p>
            {screenWidth <= 960 && (
              <img
                className="header-round-img"
                src="/images/Headr_Round_Img.webp"
                alt="Headr_Round_Img.webp"
                style={{ bottom: "-50px", left: "-5px" }}
              />
            )}
            Stay informed with V-Max Media Corp's latest insights on trending
            digital marketing topics. Explore our blog for a unique perspective
            on the ever-evolving landscape of digital trends.
          </p>
        </section>

        <section className="section">
          <ul className="tags-filter-wrapper">
            {tagItems.map((tagItem) => (
              <li
                className={`tag-filter-item ${
                  tagActiveStatus === tagItem.name
                    ? "tag-active"
                    : "tag-inactive"
                }`}
                key={tagItem.id}
                onClick={() => handleTagClick(tagItem.name)}
              >
                {tagItem.name}
              </li>
            ))}
          </ul>
          <div className="blog-section">
            {filteredBlogItems.map((blogItem) => (
              <BlogCard
                key={blogItem?.attributes?.blogTitle}
                blogItem={blogItem}
              />
            ))}
          </div>
        </section>

        <section className="section">
          <ContactForm
            text={`Let’s Make Your Next Project a Success`}
            screenWidth={screenWidth}
          />
        </section>
      </div>
    </div>
  );
}

export default Blogs;
