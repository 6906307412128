import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

export default function Footer({ screenWidth }) {
  const navigate = useNavigate();

  return (
    <div className="footer-container">
      <div className="section footer-section">
        <div className="footer-content">
          <div className="logo-area">
            <a
              href="https://www.facebook.com/profile.php?id=100093098427379"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="logo-icon"
                src="/images/Footer/VMAX.svg"
                alt="FB"
              />
            </a>
            <p>
              True experts in digital marketing, where technology fuels business
              success.
            </p>
            <p
              className="footer-policy"
              onClick={() => navigate("/policies/privacy-policy")}
            >
              Privacy Policy
            </p>
          </div>

          <div className="horizontal-layout">
            <div className="address-area">
              <h5>Find Us At</h5>
              <div>
                3600 Lysander Ln <br />
                #350, Richmond, BC <br />
                V7B 1C3
              </div>
            </div>

            <div className="connect-area">
              <h5>Connect</h5>
              <p>
                info@v-max.ca <br /> 604 203 8288
              </p>
            </div>
          </div>
          {/* )} */}
        </div>

        <div className="cards-container">
          <ul className="cards-items items-footer">
            <a
              href="https://www.youtube.com/@Vmaxmedia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="media-icon"
                src="/images/Footer/Youtube.svg"
                alt="YT"
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100093098427379"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="media-icon"
                src="/images/Footer/Facebook.svg"
                alt="FB"
              />
            </a>
            <a
              href="https://www.tiktok.com/@vmax.media"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="media-icon"
                src="/images/Footer/Tiktok.svg"
                alt="TT"
              />
            </a>
            <a
              href="https://www.instagram.com/vmax.media/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="media-icon"
                src="/images/Footer/Ins.svg"
                alt="IG"
              />
            </a>
            <a
              href="https://www.linkedin.com/showcase/v-maxmediahouse/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="media-icon"
                src="/images/Footer/LinkedIn.svg"
                alt="LI"
              />
            </a>
          </ul>
        </div>
        <p className="footer-text">
          © {new Date().getFullYear()} V-Max Media. All Rights Reserved
        </p>
      </div>
    </div>
  );
}
