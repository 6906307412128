import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ContactForm from "./ContactForm";
import MyMapComponent from "./MapComponent";
import "./ContactUs.css";

export default function ContactUs({ screenWidth }) {
  const formRef = useRef(null);
  const location = useLocation();

  // useEffect(() => {
  //   if (location.pathname === "/lets-connect") {
  //     formRef.current?.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [location.pathname]);

  return (
    <div className="page">
      <div className="main-content">
        <section className="section">
          <ContactForm
            formRef={formRef}
            text="Let’s Make Your Next Project a Success"
            subText="Fill out the form and learn more about our services."
            screenWidth={screenWidth}
          />
        </section>

        <section className="section map-section">
          <div className="map-component">
            <MyMapComponent />
          </div>
          <div className="contact-us-title-and-text">
            <div className="contact-us-intro">
              <p className="contact-us-text">
                350- 3600 Lysander Ln, Richmond, BC V7B 1C3, Canada
                <br />
                Email: info@v-max.ca
                <br />
                Phone: (604) 203-8288
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
