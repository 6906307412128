import React, { useState, useEffect, useRef } from "react";
import { getScaleToFullyStrech } from "../Utils/Methods";
import "./ImageSprite.css";

/**
 * spriteClassName: the className of the image
 * width: the container's width, only number (optional)
 * height: the container's height, only number (optional)
 * scale: the container's scale
 * largeImageSrc: the large image this sprite belongs to
 */
export default function ImageSprite({
  spriteClassName,
  width,
  height,
  scale = 1,
  largeImageSrc,
}) {
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [imageScale, setImageScale] = useState(1);
  const imageRef = useRef(null);

  useEffect(() => {
    const node = imageRef.current;
    const imageWidth = node.offsetWidth;
    const imageHeight = node.offsetHeight;
    if (width) {
      setContainerWidth(width);
      if (!height) {
        setContainerHeight((width * imageHeight) / imageWidth);
      } else {
        setContainerHeight(height);
      }
    } else {
      //!width
      if (height) {
        setContainerHeight(height);
        setContainerWidth((height * imageWidth) / imageHeight);
      } else {
        setContainerWidth(imageWidth);
        setContainerHeight(imageHeight);
      }
    }
  }, [width, height]);

  useEffect(() => {
    const node = imageRef.current;
    const imageWidth = node.offsetWidth;
    const imageHeight = node.offsetHeight;
    setImageScale(
      getScaleToFullyStrech(
        containerWidth,
        containerHeight,
        imageWidth,
        imageHeight
      )
    );
  }, [containerWidth, containerHeight]);

  return (
    <div
      className="image-sprite-container"
      style={{
        width: `${containerWidth}px`,
        height: `${containerHeight}px`,
        transform: `scale(${scale})`,
      }}
    >
      <div
        className={`image-sprite ${spriteClassName}`}
        ref={imageRef}
        style={{
          transform: `scale(${imageScale})`,
          backgroundImage: `url(${largeImageSrc})`,
        }}
        alt={spriteClassName}
      />
    </div>
  );
}
