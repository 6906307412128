export const members = [
  {
    id: 1,
    name: "Mark Wang",
    title: "CEO",
    photoSrc: "/images/AboutUs/Mark_02.webp",
  },
  {
    id: 20,
    name: "Daniel Shao",
    title: "Product Director",
    photoSrc: "/images/AboutUs/Daniel.webp",
  },
  {
    id: 15,
    name: "Anzhuo Liu",
    title: "Marketing Manager",
    photoSrc: "/images/AboutUs/Anzhuo.webp",
  },
  // {
  //   id: 3,
  //   name: "Yumi Wei",
  //   title: "Executive Assistant",
  //   photoSrc: "/images/AboutUs/Yumi.webp",
  // },
  {
    id: 19,
    name: "Evo Lulchev",
    title: "Business Development Manager",
    photoSrc: "/images/AboutUs/Evo.webp",
  },
  // {
  //   id: 6,
  //   name: "Friederike Behrends",
  //   title: "Social Media Specialist",
  //   photoSrc: "/images/AboutUs/Friederike.webp",
  // },
  {
    id: 7,
    name: "Davy Giorgi",
    title: "Creative Director",
    photoSrc: "/images/AboutUs/Davi.webp",
  },
  // {
  //   id: 5,
  //   name: "Alan Shi",
  //   title: "Film Director",
  //   photoSrc: "/images/AboutUs/Alan.webp",
  // },
  // {
  //   id: 8,
  //   name: "Carson Sharp",
  //   title: "Film Maker",
  //   photoSrc: "/images/AboutUs/Carson.webp",
  // },
  {
    id: 9,
    name: "Mykyta Kutepov",
    title: "Head of Production",
    photoSrc: "/images/AboutUs/Mykyta.webp",
  },
  // {
  //   id: 11,
  //   name: "Vasyl Utkin",
  //   title: "Video Editor",
  //   photoSrc: "/images/AboutUs/Vasyl.webp",
  // },
  // {
  //   id: 10,
  //   name: "Nessie Blanes",
  //   title: "Producer",
  //   photoSrc: "/images/AboutUs/Nessie-1.webp",
  // },
  // {
  //   id: 13,
  //   name: "Eunju Sa",
  //   title: "Graphic Designer",
  //   photoSrc: "/images/AboutUs/EJ.webp",
  // },
  // {
  //   id: 16,
  //   name: "Chris Du",
  //   title: "Software Developer",
  //   photoSrc: "/images/AboutUs/Chris.webp",
  // },
  // {
  //   id: 17,
  //   name: "Lucas Wang",
  //   title: "Software Developer",
  //   photoSrc: "/images/AboutUs/Lucas.webp",
  // },
];

export const recoVariants = {
  offscreen: {
    y: 50,
  },
  onscreen: {
    y: 0,
    rotate: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.5,
    },
  },
};

export const experienceData = [
  {
    yearsNum: 25,
    textList: [
      "Strategic Planning",
      "Digital Marketing",
      "Brand Building",
      "Performance Optimization",
      "Marketing Research",
      "Data Management & Analysis",
    ],
  },
  {
    yearsNum: 10,
    textList: [
      "Video Production",
      "TV Commercials",
      "Documentaries",
      "Films",
      "Action Sports",
      "Design and Advertising",
    ],
  },
];
