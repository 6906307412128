import { useState, useEffect } from "react";
import NavText from "../NavText/NavText";
import "./BlogAd.css";
import { getBlogLink } from "../pages/Blogs/BlogCard";
import { Link, useNavigate } from "react-router-dom";

export default function BlogAd({ blogAdItem }) {
  const [blogData, setBlogData] = useState({});
  const [blogLink, setBloglink] = useState("/");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogData = async (id) => {
      try {
        const blogRes = await fetch(
          `${process.env.REACT_APP_BLOGS_PRODUCTION}/api/blogs/${id}?populate=*`
        );
        const blogJson = await blogRes.json();
        if (blogJson?.data) {
          const attributes = blogJson?.data?.attributes;
          setBlogData(attributes);
          setBloglink(getBlogLink(attributes));
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlogData(blogAdItem.id);
  }, []);

  return (
    <div className="cards-item blog-ad-card">
      <Link to={blogLink} className="blog-ad-cover-image-container">
        <div
          className="blog-ad-cover-image"
          alt="second"
          style={{
            backgroundImage: `url(${blogData?.coverImage?.data.attributes.url})`,
          }}
        ></div>
      </Link>
      <div className="blog-ad-detail-container">
        <p>{blogData.tag}</p>
        <h3>{blogData.blogTitle}</h3>
        <NavText navTo={blogLink} text="Read Blog" type="blogAd" />
      </div>
    </div>
  );
}
