import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import { ChevronDownOutline, HomeOutline, Menu } from "react-ionicons";
import Icon from "../Icon/Icon";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { loadState } from "../../redux/actions";
import { useDispatch } from "react-redux";

function Navbar({ screenWidth, isHomeVideoInView }) {
  const location = useLocation();
  const [click, setClick] = useState(false);
  const [subName, setSubName] = useState("");
  const [homePage, setHomePage] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
  };
  const [hasServiceDropdown, setHasServiceDropdown] = useState(false);
  // const [hasProductDropdown, setHasProductDropdown] = useState(false);
  const [serviceExtraStyle, setServiceExtraStyle] = useState({});
  const [isBackGroundTrans, setIsBackGroundTrans] = useState(false);
  const { setPathPageView, setIdentity, setTrackPageView } = useTrackingCode();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadState());
  }, []);

  useEffect(() => {
    if (homePage && isHomeVideoInView) {
      setIsBackGroundTrans(true);
    } else {
      setIsBackGroundTrans(false);
    }
  }, [isHomeVideoInView, homePage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname === "/") {
      setHomePage(true);
    } else {
      setHomePage(false);
    }
    if (location.pathname.includes("service")) {
      setServiceExtraStyle({ color: "#5b3fb8" });
    } else {
      setServiceExtraStyle({});
    }
    switch (location.pathname) {
      case "/":
        setPathPageView("/");
        setTrackPageView();
        break;
      case "/service/digital-marketing-services-in-vancouver":
        setPathPageView("/service/digital-marketing-services-in-vancouver");
        setTrackPageView();
        break;
      case "/service/video-production-services":
        setPathPageView("/service/video-production-services");
        setTrackPageView();
        break;
      case "/about-us":
        setPathPageView("/about-us");
        setTrackPageView();
        break;
      case "/contact-us":
        setPathPageView("/contact-us");
        setTrackPageView();
        break;
      case "/lets-connect":
        setPathPageView("/contact-us");
        setTrackPageView();
        break;
      case "/our-work":
        setPathPageView("/our-work");
        setTrackPageView();
        break;
      case "/blogs":
        setPathPageView("/blogs");
        setTrackPageView();
      default:
        setSubName(
          "True experts of digital marketing, where creativity fuels business success."
        );
        break;
    }
    document.title = `V-MAX Media - ${subName}`;
  }, [location.pathname]);

  return (
    <nav
      className={`navbar ${
        isBackGroundTrans ? "background-transparent" : "background-white"
      }`}
    >
      <div className="navbar-container">
        <div
          className={`navbar-main-mobile ${
            isBackGroundTrans ? "background-transparent" : "background-white"
          }`}
        >
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src="/images/VMAX_Main_Logo_Color.webp" alt="VMAX logo" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <Menu
              width="45px"
              height="45px"
              color={`${isBackGroundTrans ? "#fff" : "#000"}`}
            />
          </div>
        </div>
        {screenWidth > 960 && (
          <ul className="nav-menu">
            <li className="nav-item">
              <NavLink
                to="/"
                className={`nav-links ${
                  isBackGroundTrans ? "white-color" : "black-color"
                }`}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/about-us"
                className={`nav-links ${
                  isBackGroundTrans ? "white-color" : "black-color"
                }`}
              >
                About Us
              </NavLink>
            </li>
            <li
              className="nav-item"
              onMouseEnter={() => setHasServiceDropdown(true)}
              onMouseLeave={() => setHasServiceDropdown(false)}
            >
              <Link
                className={`nav-links ${
                  isBackGroundTrans ? "white-color" : "black-color"
                }`}
                style={serviceExtraStyle}
              >
                Our Services
                <ChevronDownOutline
                  style={{ padding: "3 0 0 5" }}
                  color={isBackGroundTrans ? "#fff" : "#27292a"}
                />
              </Link>
              {hasServiceDropdown && (
                <div className="nav-link-dropdown">
                  <NavLink
                    to="/service/digital-marketing-services-in-vancouver"
                    className="nav-links-second"
                  >
                    Digital Marketing
                  </NavLink>
                  <NavLink
                    to="/service/video-production-services"
                    className="nav-links-second"
                  >
                    Video Production
                  </NavLink>
                </div>
              )}
            </li>
            <li className="nav-item">
              <NavLink
                to="/our-work"
                className={`nav-links ${
                  isBackGroundTrans ? "white-color" : "black-color"
                }`}
              >
                Our Work
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/blogs"
                className={`nav-links ${
                  isBackGroundTrans ? "white-color" : "black-color"
                }`}
              >
                Blogs
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/contact-us"
                className={`nav-links ${
                  isBackGroundTrans ? "white-color" : "black-color"
                }`}
              >
                Contact Us
              </NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-links" href="mailto:info@v-max.ca">
                <Icon
                  className="nav-icon"
                  status={isBackGroundTrans ? "bgTrans" : "notBgTrans"}
                  srcList={[
                    { status: "bgTrans", src: "/images/mail-home.svg" },
                    { status: "notBgTrans", src: "/images/mail-gradient.svg" },
                  ]}
                />
              </a>
            </li>
          </ul>
        )}

        {screenWidth <= 960 && (
          <div
            className={`${
              isBackGroundTrans ? "background-transparent" : "background-white"
            } ${click ? "nav-menu-mobile active" : "nav-menu-mobile"}`}
          >
            <div
              className={`${isBackGroundTrans ? "menu-cover-transparent" : ""}`}
            ></div>
            <ul className="nav-item-ul">
              <li
                className={`${
                  isBackGroundTrans
                    ? "nav-item-mobile-transparent"
                    : "nav-item-mobile"
                }`}
              >
                <NavLink
                  to="/"
                  className={`nav-links ${
                    isBackGroundTrans ? "white-color" : "black-color"
                  }`}
                  onClick={closeMobileMenu}
                >
                  <HomeOutline
                    style={{ marginRight: 10 }}
                    color={`nav-links ${isBackGroundTrans ? "#fff" : "#000"}`}
                  />
                  Home
                </NavLink>
              </li>
              <li className="nav-item-mobile">
                <NavLink
                  to="/about-us"
                  className={`nav-links ${
                    isBackGroundTrans ? "white-color" : "black-color"
                  }`}
                  onClick={closeMobileMenu}
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item-mobile">
                <NavLink
                  to="/service/digital-marketing-services-in-vancouver"
                  className={`nav-links ${
                    isBackGroundTrans ? "white-color" : "black-color"
                  }`}
                  onClick={closeMobileMenu}
                >
                  Digital Marketing
                </NavLink>
              </li>
              <li className="nav-item-mobile">
                <NavLink
                  to="/service/video-production-services"
                  className={`nav-links ${
                    isBackGroundTrans ? "white-color" : "black-color"
                  }`}
                  onClick={closeMobileMenu}
                >
                  Video Production
                </NavLink>
              </li>
              <li className="nav-item-mobile">
                <NavLink
                  to="/our-work"
                  className={`nav-links ${
                    isBackGroundTrans ? "white-color" : "black-color"
                  }`}
                  onClick={closeMobileMenu}
                >
                  Our Work
                </NavLink>
              </li>
              <li className="nav-item-mobile">
                <NavLink
                  to="/blogs"
                  className={`nav-links ${
                    isBackGroundTrans ? "white-color" : "black-color"
                  }`}
                  onClick={closeMobileMenu}
                >
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item-mobile">
                <NavLink
                  to="/contact-us"
                  className={`nav-links ${
                    isBackGroundTrans ? "white-color" : "black-color"
                  }`}
                  onClick={closeMobileMenu}
                >
                  Contact Us
                </NavLink>
              </li>
              <div className="nav-links nav-icon-container">
                <a href="mailto:info@v-max.ca">
                  <img
                    className="nav-icon-mobile"
                    src="/images/mail-gradient.svg"
                  />
                </a>
                <a href="tel:+16042038288">
                  <img
                    className="nav-icon-mobile"
                    src="/images/phone-gradient.svg"
                  />
                </a>
              </div>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
