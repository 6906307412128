export default function PartnerCard({
  cardName,
  refCard,
  animationSpeed,
  logoSrc,
  cardWidth,
}) {
  const extraStyle = {
    width: cardWidth,
  };

  return (
    <div
      className="bubble"
      ref={refCard}
      style={{ animationDuration: `${animationSpeed}s` }}
    >
      <div className="partner-card" style={extraStyle}>
        <p className="partner-title">{cardName}</p>
        <img className="small-icon" src={logoSrc} alt={logoSrc} />
      </div>
    </div>
  );
}
