import { useState, useEffect } from "react";
import "./Icon.css";

/**
 * srcList Sample:
 * [
 *  {status: 'default', src: '/images/default.png'},
 *  {status: 'hover', src: '/images/hover.png'}
 * ]
 */
export default function Icon({ className, status = "default", srcList }) {
  return (
    <div className={`icon-container ${className}`}>
      {srcList.map((srcItem) => (
        <img
          src={srcItem.src}
          alt={srcItem.src}
          style={{ opacity: status === srcItem.status ? 1 : 0 }}
        />
      ))}
    </div>
  );
}
