import React from "react";
import "./ShortVideosRow.css";
import VideoComponent from "../Video/VideoComponent";

export default function ShortVideosRow({
  videos,
  hasUi = false,
  hasSound = false,
}) {
  return (
    <div className="short-video-container">
      {videos.map((video) => (
        <div className="short-video-wrapper">
          <VideoComponent
            videoId={video.videoId}
            width={9}
            height={16}
            thumbnailUrl={video.thumbnailUrl}
            hasUi={hasUi}
            hasSound={hasSound}
          />
          {video.title && <p className="website-p video-p">{video.title}</p>}
        </div>
      ))}
    </div>
  );
}
