import React from "react";
import "./DigitalMarketing.css";
import ContactForm from "../ContactUs/ContactForm";
import GradientCards from "../../GradientCards/GradientCards";
import VideoComponent from "../../Video/VideoComponent";
import FeatureCards from "../../FeatureCards/FeatureCards";
import EmbeddedDashboard from "../../EmbeddedDashboard/EmbeddedDashboard";
import SEO from "../../Utils/SEO";
import ShortVideosRow from "../../ShortVideosRow/ShortVideosRow";

import {
  serviceItems,
  makerItems,
  trueExportsVideos,
  verticalItems,
} from "./DigitalMarketing.constants";

export default function DigitalMarketing({ screenWidth }) {
  return (
    <div className="page brands-page">
      <SEO
        title="Digital Marketing Service Provider in Vancouver"
        description="V-Max provides the best digital marketing services that includes social media management, SEO, paid media ads, ecommerce marketing and more."
        keywords="Digital marketing service, Digital marketing service provider in vancouver, digital marketing packages, affordable seo services for small businesses, paid social media advertising, Ecommerce promotion"
      />
      <div className="main-content">
        <section className="section header-section">
          <h4>Our Services</h4>
          <div className="header-title-container">
            <h2>We Provide</h2>
            <h1>
              Digital Marketing Services
              {screenWidth > 960 && (
                <>
                  <img
                    className="header-round-img"
                    src="/images/Headr_Round_Img.webp"
                    alt="Headr_Round_Img.webp"
                    style={{ top: 0, left: "-75px" }}
                  />
                  <img
                    className="header-sparkle-img"
                    src="/images/Header_Sparkle.webp"
                    alt="Header_Sparkle.webp"
                    style={{ top: "-20px", right: "-70px" }}
                  />
                </>
              )}
            </h1>
            <h2 className="parent-ref">
              To Take Your Brand To The Next Level
              {screenWidth <= 960 && (
                <img
                  className="header-sparkle-img"
                  src="/images/Header_Sparkle.webp"
                  alt="Header_Sparkle.webp"
                  style={{ top: "20px", right: "-5px" }}
                />
              )}
            </h2>
          </div>
          <p>
            Take your brand to new heights with our comprehensive digital
            marketing services.{screenWidth > 960 && <br />}Stay ahead in the
            dynamic online landscape with our tailored solutions designed to
            drive tangible success.
          </p>
          <div className="header-tag-container">
            {screenWidth <= 960 && (
              <img
                className="header-round-img"
                src="/images/Headr_Round_Img.webp"
                alt="Headr_Round_Img.webp"
                style={{ top: "-20px", left: "-5px" }}
              />
            )}
            {["Awareness", "Engagement", "Conversion", "Revenue"].map(
              (tagName) => (
                <div className="header-tag">
                  <div className="header-symbol-container">#</div>
                  <div>{tagName}</div>
                </div>
              )
            )}
          </div>
          <div className="header-video-container">
            <div className="video-wrapper">
              <VideoComponent
                borderRadius={`${screenWidth > 960 ? "20px" : "0"}`}
                videoId={868510933}
              />
            </div>
          </div>
        </section>

        <section className="section">
          <div className="title-container text-to-text-margin">
            Our Services
          </div>
          <p className="website-p">
            Stay competitive, stay visible – choose our digital marketing
            services to propel your brand
          </p>

          <div className="service-card-area">
            <FeatureCards features={serviceItems} />
          </div>
        </section>

        <section className="full-section">
          <div className="full-section-padding-wrapper">
            <div className="title-container text-to-text-margin">
              We are true experts to support your success!
            </div>
            <p className="website-p">
              We help to support your business and drive revenue via experienced
              E-commerce operations
            </p>
          </div>

          <ShortVideosRow
            videos={trueExportsVideos}
            hasUi={true}
            hasSound={true}
          />
        </section>

        {/* <section className="shop-section">
          <img className="shop-section-bg-left" src="/images/BG_Gradient.png" />
          <img
            className="shop-section-bg-right"
            src="/images/BG_Gradient.png"
          />
          <div className="section">
            <EmbeddedDashboard
              title="V-Max Shop Dashboard"
              intro="Our cutting-edge data visualization platform is designed to provide a comprehensive and intuitive overview of key metrics for your online store. It transforms complex data into visually impactful insights, allowing you to make informed decisions effortlessly."
              lists={[
                "One-stop Full Funnel Solution",
                "Real-time Data Visualization",
                "High Scalability Customization",
              ]}
              img1Src="/images/DigitalMarketing/Dashboard_02.webp"
              img2Src="/images/DigitalMarketing/Dashboard_01.webp"
              screenWidth={screenWidth}
            />
          </div>
        </section> */}

        <section className="section maker-section">
          <div className="title-container text-to-text-margin">
            We Are Result Makers
          </div>
          <p className="website-p">
            We help you at every stage of your customer journey.
          </p>

          <div>
            <GradientCards gradientItems={makerItems} />
          </div>
        </section>

        <section className="full-section top-vertical-section">
          {screenWidth > 960 && (
            <img
              className="top-vertical-section-bg-left"
              src={`/images/BG_Color_Gradient_${
                screenWidth > 960 ? "1" : "4"
              }.png`}
              alt="BG_Color_Gradient_1"
            />
          )}
          <img
            className="top-vertical-section-bg-right"
            src={`/images/BG_Color_Gradient_${
              screenWidth > 960 ? "2" : "3"
            }.png`}
            alt="BG_Color_Gradient_2"
          />
          <div className="top-vertical-wrapper">
            <div
              className="title-container"
              style={screenWidth <= 960 ? { width: "100%" } : {}}
            >
              Top Verticals {screenWidth <= 960 && <br />} We Can Cover
            </div>
            <div className="vertical-cards-container">
              <ul className="cards-items">
                {verticalItems.map((verticalItem) => (
                  <li className="cards-item" key={verticalItem}>
                    <div className="vertical-tag-card">
                      <span className="vertical-symbol-container">#</span>
                      <span>{verticalItem}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className="section">
          <ContactForm
            text="Grow Your Business With Us!"
            screenWidth={screenWidth}
          />
        </section>
      </div>
    </div>
  );
}
