import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./OurWork.css";
// import "../../../../public/images/OurWork/APK_logo.png";
// import "../../../../public/images/OurWork/apk_product.png";
import { portfolioVideos, ourWorkItems } from "./OurWork.constants";
import ShortVideosRow from "../../ShortVideosRow/ShortVideosRow";
import VideoComponent from "../../Video/VideoComponent";
import CaseStudyCard from "./CaseStudyCard";
import ContactForm from "../ContactUs/ContactForm";

function OurWork({ screenWidth }) {
  const portfolioRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/portfolio") {
      portfolioRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.pathname]);

  return (
    <div className="page brands-page">
      <div className="main-content">
        <section className="section header-section">
          <h4>Our Work</h4>
          <div className="header-title-container">
            <h2>Check Out Our</h2>
            <h1>
              Case Study & Portfolio
              {screenWidth > 960 && (
                <>
                  <img
                    className="header-round-img"
                    src="/images/Headr_Round_Img.webp"
                    alt="Headr_Round_Img.webp"
                    style={{ top: "-30px", left: "-75px" }}
                  />
                  <img
                    className="header-sparkle-img"
                    src="/images/Header_Sparkle.webp"
                    alt="Header_Sparkle.webp"
                    style={{ top: "-20px", right: "-80px" }}
                  />
                </>
              )}
            </h1>
          </div>
          <p>
            V-Max Media drives digital success through strategic innovation.
            <br />
            From comprehensive brand solutions to creative content production,
            we elevate your online presence for maximum impact.
          </p>
          <div className="header-tag-container">
            {screenWidth <= 960 && (
              <>
                <img
                  className="header-round-img"
                  src="/images/Headr_Round_Img.webp"
                  alt="Headr_Round_Img.webp"
                  style={{ top: "-30px", left: "5px" }}
                />
                <img
                  className="header-sparkle-img"
                  src="/images/Header_Sparkle.webp"
                  alt="Header_Sparkle.webp"
                  style={{ top: "30px", right: "20px" }}
                />
              </>
            )}
            {[
              "Strategy Planning",
              "Brand Building",
              "Performance Tracking",
            ].map((tagName) => (
              <div className="header-tag header-tag-smaller">
                <div className="header-symbol-container">#</div>
                <div>{tagName}</div>
              </div>
            ))}
          </div>
        </section>

        <section className="section">
          <div className="case-study">
            <div
              className="title-container text-to-text-margin"
              style={{ justifyContent: "flex-start" }}
            >
              Case Study
            </div>
            {ourWorkItems.map((ourWorkItem, index) => (
              <CaseStudyCard
                index={index}
                screenWidth={screenWidth}
                ourWorkItem={ourWorkItem}
              />
            ))}
          </div>
        </section>

        <section className="full-section parent-ref">
          <div className="current-ref" ref={portfolioRef}></div>
          <div className="full-section-padding-wrapper">
            <div
              className="title-container text-to-text-margin"
              style={{ justifyContent: "flex-start" }}
            >
              Portfolio
            </div>
            <p className="website-p" style={{ textAlign: "left" }}>
              Explore V-Max's diverse portfolio, showcasing our expertise in
              transforming businesses through our in-house video production.
            </p>
          </div>
          <ShortVideosRow
            videos={portfolioVideos}
            hasUi={true}
            hasSound={true}
          />
          <div className="full-section-padding-wrapper">
            <div className="portfolio-video-wrapper">
              <VideoComponent
                videoId={916154265}
                hasUi={true}
                hasSound={true}
              />
              <p className="website-p video-p">Film Quality Productions</p>
            </div>
            <div className="portfolio-video-wrapper">
              <VideoComponent
                videoId={916154306}
                hasUi={true}
                hasSound={true}
              />
              <p className="website-p video-p">Commercial Content Creation</p>
            </div>
          </div>
        </section>

        <section className="section">
          <ContactForm
            text={`Let’s Make Your Next Project a Success`}
            screenWidth={screenWidth}
          />
        </section>
      </div>
    </div>
  );
}

export default OurWork;
