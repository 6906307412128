import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TAG_ACTIVE_STATUS } from "../../../Global.constants";
import { formatDate } from "../../Utils/Methods";

export const getBlogLink = (blogData) => {
  const transformTitleToSlug = (title) => {
    return title.trim().toLowerCase().replace(/\s+/g, "-");
  };
  let validTagSlug;
  let validBlogSlug = blogData?.blogSlug;
  if (blogData?.tagSlug) {
    validTagSlug = transformTitleToSlug(blogData?.tagSlug);
  } else {
    validTagSlug = transformTitleToSlug(blogData?.tag);
  }
  return `/blogs/${validTagSlug}/${validBlogSlug}`;
};

export default function BlogCard({ blogItem }) {
  const navigate = useNavigate();
  const imgSrc = blogItem.attributes?.coverImage.data.attributes.url;
  const tagActiveStatus = useSelector((state) => state.tagActiveStatus);
  const [blockLink, setBlockLink] = useState("");

  useEffect(() => {
    setBlockLink(getBlogLink(blogItem?.attributes));
  }, []);

  return (
    <div className="blog-container">
      <Link to={blockLink}>
        <div
          className="blog-cover-image"
          alt="second"
          style={{ backgroundImage: `url(${imgSrc})` }}
        >
          <div
            className={`blog-tag ${
              tagActiveStatus === TAG_ACTIVE_STATUS.NONE
                ? "tag-inactive"
                : "tag-active"
            }`}
          >
            {blogItem.attributes?.tag}
          </div>
        </div>
      </Link>
      <Link to={blockLink} style={{ textDecoration: "none" }}>
        <div className="blog-title">{blogItem.attributes?.blogTitle}</div>
      </Link>
      <div className="blog-details-container">
        <span>{formatDate(blogItem.attributes?.publishedDate)}</span>
        <span>{blogItem.attributes?.authorName}</span>
      </div>
    </div>
  );
}
