import React from "react";
import GradientIcon from "../GradientIcon/GradientIcon";
import "./FeatureCards.css";

const FeatureCard = ({ feature }) => (
  <div className="feature-grid-item">
    <GradientIcon iconWidth="10%" iconSrc={feature.url} />
    <h3>{feature.title}</h3>
    <div className="feature-description">{feature.description}</div>
  </div>
);

const FeatureCards = ({ features }) => {
  return (
    <div className="feature-grid">
      {features.map((feature) => (
        <FeatureCard key={feature.id} feature={feature} />
      ))}
    </div>
  );
};

export default FeatureCards;
