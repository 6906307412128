import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Select, ConfigProvider } from "antd";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";
import { Button, Modal } from "antd";
import { useDispatch } from "react-redux";
import { setValue } from "../../../redux/actions";

function ContactForm({
  text,
  subText = "Fill the form and we will get back to you as soon as possible.",
  formRef,
  screenWidth,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  // const [occupation, setOccupation] = useState("");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const isEmpty = (value) => {
    return !value || value.trim() === "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      alert("Email Format Wrong");
      return;
    }

    if (isEmpty(firstName)) {
      alert("First Name can't be empty");
      return;
    }

    if (isEmpty(lastName)) {
      alert("Last Name can't be empty");
      return;
    }

    if (isEmpty(message)) {
      alert("Message can't be empty");
      return;
    }

    // contact form(use emailJs site for reference)
    const templateParams = {
      from_name: `${firstName} ${lastName}`,
      from_mail: email,
      from_phone_number: phone,
      from_selection: service,
      user_message: message,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_FORM,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          // showModal();
          dispatch(setValue("pathBeforeThankYouPage", location.pathname));
          navigate("/thank-you");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const clearValues = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setService(null);
    setMessage("");
  };

  const showModal = () => {
    setIsModalOpen(true);
    setTimeout(() => {
      handleCancel();
    }, "5000");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    clearValues();
  };

  return (
    <section className="contact-form-section" ref={formRef}>
      <Modal
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width="800px"
      >
        <div className="section-title contact-form-title">
          Thank you for reaching out!
        </div>
        <p className="website-p" style={{ marginBottom: "30px" }}>
          Our team will contact you soon.
        </p>
      </Modal>
      <div className="contact-form-title section-title">{text}</div>
      <div>{subText}</div>

      <form className="form" onSubmit={handleSubmit}>
        <div className={screenWidth < 500 ? "" : "form-row"}>
          <div className="form-item">
            <div>First Name</div>
            <input
              type="name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div className="form-item">
            <div>Last Name</div>
            <input
              type="name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>

        <div className={screenWidth < 500 ? "" : "form-row"}>
          <div className="form-item">
            <div>Email</div>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-item">
            <div>Phone Number</div>
            <input
              type="name"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>

        <div className="form-item">
          <div>What Services are you interested in?</div>
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  multipleItemBg: "#F2F4F8",
                  selectorBg: "#F2F4F8",
                  optionFontSize: "14px",
                  optionHeight: "35px",
                  multipleItemHeight: "35px",
                  multipleItemBorderColor: "transparent",
                },
              },
            }}
          >
            <Select
              variant="borderless"
              className="selection"
              style={{
                flex: 1,
              }}
              options={[
                {
                  value: "New Website",
                  label: "New Website",
                },
                {
                  value: "Logo / Graphic Design",
                  label: "Logo / Graphic Design",
                },
                {
                  value: "Social Media Management",
                  label: "Social Media Management",
                },
                {
                  value: "Search Engine Marketing (SEM/PPC)",
                  label: "Search Engine Marketing (SEM/PPC)",
                },
                {
                  value: "Search Engine Optimization (SEO)",
                  label: "Search Engine Optimization (SEO)",
                },
                {
                  value: "Content Marketing (Video and Photo Production)",
                  label: "Content Marketing (Video and Photo Production)",
                },
                {
                  value: "E-commerce",
                  label: "E-commerce",
                },
                {
                  value: "Influencer Partnerships",
                  label: "Influencer Partnerships",
                },
                {
                  value: "Others",
                  label: "Others",
                },
              ]}
              onChange={(e) => {
                setService(e);
              }}
              value={service}
            />
          </ConfigProvider>
        </div>

        <div className="form-item">
          <div>Tell Us About Your Project!</div>
          <textarea
            className="textarea"
            value={message}
            limit={200}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        <div className="proposal-button-area">
          <button className="proposal-button" type="submit">
            Submit
          </button>
        </div>
      </form>
    </section>
  );
}

export default ContactForm;
