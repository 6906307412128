import React from "react";
import "./ExperienceCard.css";

export default function ExperienceCard({ experienceItem }) {
  return (
    <div className="exp-card-container">
      <div className="year-num-title-container">
        <h3 className="years-num-title no-margin">
          <p className="large-title year-text no-margin">
            {experienceItem.yearsNum}+
          </p>
        </h3>
        <p className="experience-text no-margin">Years experience in</p>
      </div>
      <ul className="experience-list">
        {experienceItem.textList.map((textItem) => (
          <li className="middle-text">{textItem}</li>
        ))}
      </ul>
    </div>
  );
}
