import react, { useState, useEffect } from "react";
import BlogAd from "./BlogAd";

const generateCodeNodes = (codeText) => {
  const entries = codeText.split("\n");
  const codeObject = {};
  entries.forEach((entry) => {
    if (entry.trim()) {
      // Split each entry by the equals sign to get key-value pairs
      const [key, value] = entry.split("=");
      // Assign these key-value pairs to the result object
      codeObject[key.trim()] = value.trim();
    }
  });
  //   console.log(codeObject);
  switch (codeObject.type) {
    case "blogAds":
      const blogAdItems = [];
      const ids = codeObject.ids.split(",");
      ids.forEach((id) => {
        blogAdItems.push({ id: id.trim() });
      });
      return (
        <ul className="cards-items blog-cards-items">
          {blogAdItems.map((blogAdItem) => (
            <BlogAd blogAdItem={blogAdItem} />
          ))}
        </ul>
      );
    default:
      return <></>;
  }
};

export default function CodeGenerator({ codeText }) {
  return <>{codeText && generateCodeNodes(codeText)}</>;
}
