export function formatDate(dateString) {
  // Create a date object using the date string
  const date = new Date(dateString + "T00:00:00-08:00");
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "America/Los_Angeles",
  };
  const res = date.toLocaleDateString("en-US", options);
  return res === "Invalid Date" ? "" : res;
}

export const getScaleToFullyStrech = (
  containerWidth,
  containerHeight,
  itemWidth,
  itemHeight
) => {
  const widthScale = containerWidth / itemWidth;
  const heightScale = containerHeight / itemHeight;
  return Math.max(widthScale, heightScale);
};
