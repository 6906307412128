import { useState } from "react";
import GradientIcon from "../../GradientIcon/GradientIcon";
import "./ServiceCard.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Icon from "../../Icon/Icon";

export default function ServiceCard({ serviceCardItem, screenWidth }) {
  const navigate = useNavigate();
  const [showBG, setShowBG] = useState(false);

  return (
    <motion.div
      className="cards-item service-card-item"
      onMouseEnter={() => setShowBG(true)}
      onMouseLeave={() => setShowBG(false)}
    >
      {screenWidth > 960 && serviceCardItem.bgSide === "left" && (
        <motion.img
          className="service-bg-left"
          src={"/images/BG_Gradient_Group.png"}
          initial={{ opacity: 0 }}
          animate={{ opacity: showBG ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        />
      )}
      {screenWidth > 960 && serviceCardItem.bgSide === "right" && (
        <motion.img
          className="service-bg-right"
          src={"/images/BG_Gradient_Group.png"}
          initial={{ opacity: 0 }}
          animate={{ opacity: showBG ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        />
      )}
      {screenWidth <= 960 && serviceCardItem.bgSide === "left" && (
        <img
          className="service-bg-left-mobile"
          src={"/images/BG_Gradient_Group.png"}
          initial={{ opacity: 0 }}
          animate={{ opacity: screenWidth > 960 && showBG ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        />
      )}
      {screenWidth <= 960 && serviceCardItem.bgSide === "right" && (
        <img
          className="service-bg-right-mobile"
          src={"/images/BG_Gradient_Group.png"}
          initial={{ opacity: 0 }}
          animate={{ opacity: showBG ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        />
      )}
      <GradientIcon iconSrc={serviceCardItem.imgSrc} />
      <h4 className="no-margin" style={{ fontSize: "1.2rem" }}>
        {serviceCardItem.title}
      </h4>
      <p className="no-margin">{serviceCardItem.text}</p>
      <ul>
        {serviceCardItem.listText.map((text) => (
          <li key={text}>{text}</li>
        ))}
      </ul>
      <motion.div
        className="learn-more-container"
        onClick={() => navigate(serviceCardItem.linkTo)}
        animate={{ scale: screenWidth > 960 && showBG ? 1.2 : 1 }}
        transition={{ duration: 0.5 }}
      >
        <span className={`${showBG ? "gradient-color-text" : "default-text"}`}>
          Learn More
        </span>
        <Icon
          className="right-arrow-icon"
          status={showBG ? "parentHover" : "default"}
          srcList={[
            { status: "default", src: "/images/Right_Arrow.svg" },
            { status: "parentHover", src: "/images/Gradient_Right_Arrow.svg" },
          ]}
        />
      </motion.div>
    </motion.div>
  );
}
