import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import ContactForm from "../ContactUs/ContactForm";
import CompanyIcons from "../../CompanyIcons/CompanyIcons";
import BrandsNumCard from "../../BrandsNumCard/BrandsNumCard";
import VideoComponent from "../../Video/VideoComponent";
import { Link } from "react-router-dom";
import { Carousel } from "antd";
import {
  intro,
  mainNumItems,
  whyWork,
  homeVideos,
  serviceCardItems,
  trustedByItems,
} from "./Home.constants.js";
import ShortVideosRow from "../../ShortVideosRow/ShortVideosRow";
import SEO from "../../Utils/SEO";
import { useInView } from "framer-motion";
import ServiceCard from "./ServiceCard.js";
import {
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import NavText from "../../NavText/NavText.js";
import ImageSprite from "../../ImageSprite/ImageSprite";

export default function Home({
  screenWidth,
  screenHeight,
  homeVideoRef,
  setIsHomeVideoInView,
}) {
  const myRef = useRef(null);
  const carouselRef = useRef(null);
  const [videoHeight, setVideoHeight] = useState(screenHeight);
  const isInView = useInView(homeVideoRef);

  useEffect(() => {
    setIsHomeVideoInView(isInView);
  }, [isInView]);

  useEffect(() => {
    const node = myRef.current;
    const height = node.offsetHeight;
    setVideoHeight(height);
  }, [screenWidth, screenHeight]);

  return (
    <div className="home-page">
      <SEO
        title="V-Max Media: Digital Marketing Agency Based in Vancouver"
        description="V-Max Media, your trusted digital marketing agency in Vancouver. Elevate your business with cutting-edge digital marketing services tailored to your needs."
        keywords="digital marketing agency, digital marketing services, best digital marketing agencies, digital marketing agency for small business, digital marketing services near me, marketing agency for startups"
      />
      <div className="main-content">
        <section style={{ position: "relative" }}>
          <div className="home-video-ref-box" ref={homeVideoRef}></div>
          <div className="home-page-video-container" ref={myRef}>
            <div className="home-page-video-wrapper">
              {screenWidth > 960 ? (
                <VideoComponent
                  videoId={924674907}
                  horizontalStrech={true}
                  width={screenWidth}
                  height={videoHeight}
                  maskType="home"
                  thumbnailUrl="/images/Home/home_desktop_thumbnail.jpg"
                />
              ) : (
                <VideoComponent
                  videoId={924675692}
                  verticalStrech={true}
                  width={screenWidth}
                  height={videoHeight}
                  maskType="home"
                  thumbnailUrl="/images/Home/home_mobile_thumbnail.jpg"
                />
              )}
            </div>
            <div className="graph-over-video">
              <div className="video-section-padding-wrapper">
                <div className="home-title">Digital Marketing Agency</div>
                <div className="display-linebreak body-font">{intro}</div>
                <div className="home-button-area">
                  <Link to="/contact-us" className="proposal-button">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section first-section">
          <div>
            <div className="title-container">We are Trusted By</div>
            <div className="cards-container">
              <ul className="cards-items trusted-by-logos">
                {trustedByItems.map((trustedByItem) => (
                  <li>
                    <ImageSprite
                      spriteClassName={trustedByItem.spriteClassName}
                      height={
                        screenWidth > 960
                          ? trustedByItem.desktopHeight
                          : trustedByItem.mobileHeight
                      }
                      largeImageSrc="/images/All_Logos.webp"
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="title-container">Our Services</div>
          <div className="cards-container">
            <ul className="cards-items service-cards-items">
              {serviceCardItems.map((serviceCardItem) => (
                <ServiceCard
                  key={serviceCardItem.title}
                  serviceCardItem={serviceCardItem}
                  screenWidth={screenWidth}
                />
              ))}
            </ul>
          </div>
        </section>

        <section className="section">
          <div className="title-container text-to-text-margin">
            We Have a Highly Diverse Team in V-Max
          </div>

          <p className="website-p">{whyWork}</p>

          <div className="cards-container main-num-cards-container">
            <ul className="cards-items main-num-cards-items">
              {mainNumItems.map((brandsNumItem) => (
                <BrandsNumCard
                  brandsNumItem={brandsNumItem}
                  screenWidth={screenWidth}
                  key={brandsNumItem.info}
                />
              ))}
            </ul>
          </div>
          <NavText navTo="/about-us" text="Read more about our team" />
        </section>

        <section className="full-section">
          <ShortVideosRow videos={homeVideos} hasUi={true} hasSound={true} />
          <div className="full-section-padding-wrapper">
            <NavText navTo="/portfolio" text="See more portfolio projects" />
          </div>
        </section>

        <section className="full-section client-section">
          <img
            className="client-section-bg-left"
            src={`/images/BG_Color_Gradient_${
              screenWidth > 960 ? "1" : "4"
            }.png`}
            alt="BG_Color_Gradient_1"
          />
          <img
            className="client-section-bg-right"
            src={`/images/BG_Color_Gradient_${
              screenWidth > 960 ? "2" : "3"
            }.png`}
            alt="BG_Color_Gradient_2"
          />
          <div className="full-section-padding-wrapper">
            <div className="review-container">
              <div className="title-container">
                What Our Clients Have to Say
              </div>

              <div className="carousel-wrapper">
                {screenWidth <= 960 && (
                  <div className="arrows">
                    <LeftOutlined
                      onClick={() => {
                        carouselRef.current.prev();
                      }}
                    />
                    <RightOutlined
                      onClick={() => {
                        carouselRef.current.next();
                      }}
                    />
                  </div>
                )}
                <Carousel
                  dots={false}
                  ref={carouselRef}
                  slidesToScroll={1}
                  slidesToShow={screenWidth > 960 ? 2 : 1}
                >
                  <div className="review-item">
                    <div className="review-card">
                      <div className="review-header">
                        <ImageSprite
                          spriteClassName="b-box-sprite"
                          height={54}
                          largeImageSrc="/images/All_Logos.webp"
                        />
                      </div>
                      <div className="review-text">
                        "Thanks to V-Max Media's outstanding paid media and
                        video production services, our Boxing Day campaign saw
                        an incredible 400% surge in sales."
                      </div>
                    </div>
                  </div>

                  <div className="review-item">
                    <div className="review-card">
                      <div className="review-header">
                        <ImageSprite
                          spriteClassName="apk-sprite"
                          height={54}
                          largeImageSrc="/images/All_Logos.webp"
                        />
                      </div>
                      <div className="review-text">
                        "V-Max Media played a pivotal role in elevating our
                        Academic Pre-Kindergarten brand through their
                        exceptional video production and social media management
                        services."
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
            <NavText navTo="/our-work" text="Read our project case studies" />
          </div>
        </section>

        <section className="section">
          <div className="title-container">Platforms We Are Skilled In</div>
          <CompanyIcons screenWidth={screenWidth} />
        </section>

        <section className="section">
          <ContactForm
            text={`Let’s Make Your Next Project a Success`}
            screenWidth={screenWidth}
          />
        </section>
      </div>
    </div>
  );
}
