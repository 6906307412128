import * as React from "react";

export default function CustomKeyFrames({ scrollPosition, animationName }) {
  return (
    <style>
      {`@keyframes animateContainer {
          100% {
            transform: translateX(${-scrollPosition / 2}px);
          }
      }`}
    </style>
  );
}
