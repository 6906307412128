import React from "react";
import "./MemberCard.css";

export default function MemberCard({ member }) {
  return (
    <div className="cards-item cards-item-member">
      <div className="icon-area-member">
        <h3 className="member-name">{member.name}</h3>
        <p className="member-title">{member.title}</p>
        {member.photoSrc !== "N/A" ? (
          <img
            className="icon-member"
            src={member.photoSrc}
            alt={member.photoSrc}
          />
        ) : (
          <div className="icon-member"></div>
        )}
      </div>
    </div>
  );
}
