import React from "react";
import "./GradientIcon.css";

export default function GradientIcon({ iconSrc, iconWidth }) {
  return (
    <div className="icon-box">
      <img 
        src={iconSrc} 
        alt={iconSrc} 
        style={{ width: iconWidth }} 
      />
    </div>
  );
}
