export default function CaseStudyCard({ screenWidth, ourWorkItem }) {
  return (
    <>
      {screenWidth > 960 ? (
        <div className="card">
          <div className="card-header">
            <div className="logo">
              <img src={ourWorkItem.logoSrc} alt="logo" />
            </div>
            <h2>{ourWorkItem.title}</h2>
            <p>{ourWorkItem.text}</p>
            {ourWorkItem.buttonLink ? (
              <a
                href={ourWorkItem.buttonLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <button className="btn">View Case Study Detail</button>
              </a>
            ) : (
              <button className="btn">Case Study Coming Soon</button>
            )}
          </div>
          <div className="card-body">
            <div
              className="card-body-cover-image"
              style={{
                backgroundImage: `url(${ourWorkItem.coverSrc})`,
              }}
            />
          </div>
        </div>
      ) : (
        <div className="card-mobile">
          <div className="card-header-mobile">
            <div className="logo">
              <img src={ourWorkItem.logoSrc} alt="logo" />
            </div>
            <h2>{ourWorkItem.title}</h2>
            <p>{ourWorkItem.text}</p>
            <div className="btn-wrapper">
              {ourWorkItem.buttonLink ? (
                <a
                  href="https://case-study-baby.s3.us-east-2.amazonaws.com/V_Max_Case_Study_B_Box.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <button className="btn">View Case Study Detail</button>
                </a>
              ) : (
                <button className="btn">Case Study Coming Soon</button>
              )}
            </div>
          </div>
          <div className="card-body-mobile">
            <img src={ourWorkItem.coverSrc} alt="cover image" />
          </div>
        </div>
      )}
    </>
  );
}
