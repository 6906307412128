import React from "react";
import "./CompanyIcons.css";
import ImageSprite from "../ImageSprite/ImageSprite";
import { companyIconItems } from "../pages/Home/Home.constants";

const CompanyIcons = ({ screenWidth }) => {
  return (
    <div className="cards-container">
      <ul className="cards-items reco-cards-items">
        {companyIconItems.map((companyIconItem) => (
          <li className="cards-item">
            <ImageSprite
              spriteClassName={companyIconItem.spriteClassName}
              height={
                screenWidth > 960
                  ? companyIconItem.desktopHeight
                  : companyIconItem.mobileHeight
              }
              largeImageSrc="/images/All_Logos.webp"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CompanyIcons;
