import React, { useState, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ArticleGenerator from "../ArticleGenerator/ArticleGenerator.js";
import { formatDate } from "../Utils/Methods.js";
import { useNavigate } from "react-router-dom";

const PolicyPage = ({ screenWidth }) => {
  const url = window.location.href;
  const policySlug = url.split("/").pop();
  const [policyItem, setPolicyItem] = useState({});
  const [policyTitle, setPolicyTitle] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPolicyPosts = async () => {
      try {
        const policyRes = await fetch(
          `${process.env.REACT_APP_BLOGS_PRODUCTION}/api/policies?filters[policySlug][$eq]=${policySlug}`
        );
        const policyJson = await policyRes.json();
        if (policyJson?.data) {
          const attributes = policyJson?.data[0]?.attributes;
          console.log(attributes);
          setPolicyItem(attributes);
          setPolicyTitle(attributes?.policyTitle);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPolicyPosts();
  }, []);

  return (
    <div className="page">
      <div className="main-content">
        <section className="section" style={{ paddingTop: 0 }}>
          <button
            className="blog-button"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(-1);
            }}
          >
            {screenWidth > 960 ? "Go Back" : ""}
            <ArrowLeftOutlined
              style={{ color: "var(--theme-color-primary)" }}
            />
          </button>
          <div className="website-title-blog">{policyTitle}</div>
          <div
            className="blog-page-date-container"
            style={{ justifyContent: "flex-start" }}
          >
            <span>Last updated: {formatDate(policyItem?.lastUpdated)}</span>
          </div>
          <ArticleGenerator textItems={policyItem?.policyContent} />
        </section>
      </div>
    </div>
  );
};

export default PolicyPage;
