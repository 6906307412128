import React, { useState, useEffect, useRef } from "react";
import VideoComponent from "../../Video/VideoComponent";
import FeatureCards from "../../FeatureCards/FeatureCards";
import ContactForm from "../ContactUs/ContactForm";
import {
  videoItems,
  vpCollapseItems,
  bestPracticeVideos,
} from "./VideoProduction.constants";
import CollapseCard from "../../CollapseCard/CollapseCard";
import SEO from "../../Utils/SEO";
import ProductionCard from "./ProductionCard";
import { offerItems, productionImgs } from "./VideoProduction.constants";
import "./VideoProduction.css";
import ShortVideosRow from "../../ShortVideosRow/ShortVideosRow";

export default function VideoProduction({ screenWidth }) {
  const [targetPosition, setTargetPosition] = useState(0);
  const offerCardWidth = 506.75 + 24;
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);

  // Create a ref for the element you want to monitor
  const monitoredElementRef = useRef(null);

  // Function to check overflow
  function checkOverflow(element) {
    const isOverflowing = element.scrollWidth > element.clientWidth;
    const overflowLeft = element.scrollLeft > 0;
    const overflowRight =
      Math.ceil(element.scrollLeft + element.clientWidth) < element.scrollWidth;

    let result = "";
    if (isOverflowing) {
      if (overflowLeft && overflowRight) {
        // Overflow on both sides
        setLeftArrow(true);
        setRightArrow(true);
      } else if (overflowLeft) {
        // Overflow on the left side
        setLeftArrow(true);
        setRightArrow(false);
      } else if (overflowRight) {
        // Overflow on the right side
        setLeftArrow(false);
        setRightArrow(true);
      }
    } else {
      // "No Overflow"
      setLeftArrow(false);
      setRightArrow(false);
    }
  }

  useEffect(() => {
    // Ensure the element is available
    const element = monitoredElementRef.current;
    if (element) {
      checkOverflow(element);
      // Define a scroll event listener that calls checkOverflow
      const onScroll = () => checkOverflow(element);

      // Add event listener
      element.addEventListener("scroll", onScroll);

      // Remove event listener on cleanup
      return () => element.removeEventListener("scroll", onScroll);
    }
  }, [screenWidth]); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    if (screenWidth > 960) {
      const container = document.querySelector(".production-cards-items");
      container.scrollLeft = targetPosition;
    }
  }, [screenWidth]);

  const toLeft = () => {
    const container = document.querySelector(".production-cards-items");
    const scrollStep = 20; // Adjust the scrolling speed (larger values scroll faster)
    function scroll() {
      if (container.scrollLeft > Math.max(0, targetPosition - offerCardWidth)) {
        setTargetPosition(Math.max(0, targetPosition - offerCardWidth));
        container.scrollLeft -= scrollStep;
        requestAnimationFrame(scroll);
      }
    }
    scroll();
  };

  function toRight() {
    const container = document.querySelector(".production-cards-items");
    const scrollStep = 20; // Adjust the scrolling speed (larger values scroll faster)
    function scroll() {
      if (
        Math.ceil(container.scrollLeft) <
        Math.min(
          container.scrollWidth - container.clientWidth,
          targetPosition + offerCardWidth
        )
      ) {
        setTargetPosition(
          Math.min(
            container.scrollWidth - container.clientWidth,
            targetPosition + offerCardWidth
          )
        );
        container.scrollLeft += scrollStep;
        // console.log("scrollLeft", Math.ceil(container.scrollLeft));
        // console.log(container.scrollWidth - container.clientWidth);
        requestAnimationFrame(scroll);
      }
    }
    scroll();
  }

  return (
    <div className="page">
      <SEO
        title="Film and Video Production Services in Vancouver"
        description="V-Max Media Provides affordable Film and Video Production Services"
        keywords="explainer video company, corporate video production, video production services, videography services, explainer video agency, music video production, corporate videographer, corporate video services, film and video production, marketing video production"
      />
      <div className="main-content">
        <section className="section header-section">
          <h4>Our Services</h4>
          <div className="header-title-container">
            <h2>From the Idea to the Screen</h2>
            <h1>
              We Find Your Audience & Tell Your Story
              {screenWidth > 960 && (
                <>
                  <img
                    className="header-round-img"
                    src="/images/Headr_Round_Img.webp"
                    alt="Headr_Round_Img.webp"
                    style={{ top: "-25px", left: "-55px" }}
                  />
                  <img
                    className="header-sparkle-img"
                    src="/images/Header_Sparkle.webp"
                    alt="Header_Sparkle.webp"
                    style={{ top: "-20px", right: "-70px" }}
                  />
                </>
              )}
            </h1>
          </div>
          <p>
            At V-Max, we redefine the art of storytelling through our
            comprehensive in-house content development & video production
            services designed to elevate your brand. With a commitment to
            excellence, we specialize in crafting captivating content that
            resonates with your audience. 
          </p>
          <div className="header-tag-container">
            {screenWidth <= 960 && (
              <>
                <img
                  className="header-round-img"
                  src="/images/Headr_Round_Img.webp"
                  alt="Headr_Round_Img.webp"
                  style={{ top: "-30px", left: "5px" }}
                />
                <img
                  className="header-sparkle-img"
                  src="/images/Header_Sparkle.webp"
                  alt="Header_Sparkle.webp"
                  style={{ top: "30px", right: "20px" }}
                />
              </>
            )}
            {["Content Production", "Social Visuals", "Brand Commercial"].map(
              (tagName) => (
                <div className="header-tag header-tag-smaller">
                  <div className="header-symbol-container">#</div>
                  <div>{tagName}</div>
                </div>
              )
            )}
          </div>
          <div className="header-video-container">
            <div className="video-wrapper">
              <VideoComponent
                borderRadius={`${screenWidth > 960 ? "20px" : "0"}`}
                videoId={868510933}
              />
            </div>
          </div>
        </section>

        <section className="section">
          <div className="title-container">We Can Help You With</div>

          <div className="service-card-area">
            <FeatureCards features={videoItems} />
          </div>
        </section>

        <section className="full-section">
          <div className="cards-container creator-cards-container">
            {screenWidth > 960 && leftArrow && (
              <div className="left-arrow-box">
                <img
                  src="/images/Creators/left-arrow-new.svg"
                  alt="left-arrow"
                  onClick={toLeft}
                />
              </div>
            )}
            {screenWidth > 960 && rightArrow && (
              <div className="right-arrow-box">
                <img
                  src="/images/Creators/right-arrow-new.svg"
                  alt="right-arrow"
                  onClick={toRight}
                />
              </div>
            )}
            <ul
              className="cards-items production-cards-items"
              ref={monitoredElementRef}
            >
              {productionImgs.map((productionImg) => (
                <div
                  key={productionImg.key}
                  className="cards-item production-image"
                  style={{ backgroundImage: `url(${productionImg.imgSrc})` }}
                ></div>
              ))}
            </ul>
          </div>
        </section>

        <section className="section">
          <div className="title-container text-to-text-margin">
            Why Choose Our In-house Content & Video Production Service
          </div>
          <p className="website-p">
            V-Max production team brings a multitude of advantages tailored to
            meet your unique needs.
          </p>

          <div>
            <CollapseCard collapseItems={vpCollapseItems} />
          </div>
        </section>

        <section className="full-section">
          <div className="title-container">Our Gear</div>
          <ShortVideosRow
            videos={bestPracticeVideos}
            hasUi={true}
            hasSound={true}
          />
        </section>

        <section className="section">
          <ContactForm
            text={`Let’s Create Something Amazing`}
            screenWidth={screenWidth}
          />
        </section>
      </div>
    </div>
  );
}
